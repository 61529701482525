import { LoadState } from "../../constants/enums";
import { IProduct, IProductState } from "./Product.types";

export const defaultProduct: IProduct = {
  product_id: 0,
  product_code: "",
  product_name: "",
  brand_code: "",
  brand_name: "",
  category_code: "",
  category_name: "",
  description: "",
  image: [""],
  unique_product: "",
  vehicle_make: "",
  vehicle_model: "",
  product_available_quantity: 0,
  compatible_with_suv: "",
  compatible_with_sedan: "",
  compatible_with_hatchback: "",
  product_model_number: "",
  product_order_limit: 0,
  product_availibility: "",
  extra_price: 0,
  shipping_and_returns: [""],
  offers: [""],
  review: [{ date: "", rating: 0, comment: "", reviewer_name: "" }],
  color: [""],
  package_price_excluding_gst: 0,
  price: 0,
  gst_percentage: 0,
  gst_amount: 0,
  vehicle_category: "",
  status: "",
  comment: "",
  create_dt: "",
  insert_dt: "",
  product_slug: "",
};

export const defaultProductState: IProductState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  product: {
    data: defaultProduct,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
