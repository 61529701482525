//change the ID filter part. in API and filterDropDown. Also check the values coming from backend and agrenge them according
import { AspectRatio } from "@mui/icons-material";
import moment from "moment";
import React from "react";
import { api } from "../../../api/api";
import { StandadCard } from "../../../components/Cards";
import { PageContainer } from "../../../components/container/PageContainer";
import { ContextMenu } from "../../../components/ContextMenu/ContextMenu";
import { DataTable } from "../../../components/Table/DataTable";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../components/Table/interfaces/IDataTableProps";
import { RefreshPlugin } from "../../../components/Table/plugins";
import {
  useAuth,
  userRoles,
} from "../../../contexts/AuthProvider/AuthProvider";
import { useMessagesPopup } from "../../../contexts/MessagesPopupProvider/MessagesPopupProvider";
import { Breadcrumb } from "../../../layout/fullLayout/breadcrumb/Breadcrumb";
import { IBreadcrumbProps } from "../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { IActivationTeamlead } from "./interface/IActivationTeamlead";
// import { ViewActivationTeamleadDialog } from "./ViewsalesManagerReport/ViewActivationTeamlead";
import { Avatar, Box, Grid, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CustomSelect,
  CustomTypography,
} from "../../../components/formsComponents";
import { CustomDatePicker } from "../../../components/formsComponents/CustomPicker";
import { DateFilterDropdown } from "../../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { useDateFilter } from "../../../components/Table/hooks/useDateFilter";
import { CSVExportPlugin } from "../../../components/Table/plugins";
import { IBranchOffice } from "../../../redux/common/types";
import { IStoreState } from "../../../redux/initialStoreState";

export const SowTeamLeadTableReport: React.FC = () => {
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/",
      title: "get-sales-report",
    },
    {
      title: "Sales-Table-Report",
    },
  ];

  const dispatch = useDispatch<any>();
  const [ActivationList, setActivationList] = React.useState<
    IActivationTeamlead[]
  >([]);
  const [loading, setLoading] = React.useState(false);
  const [openView, setOpenView] = React.useState<IActivationTeamlead | null>(
    null
  );
  const [TeamleadId, setTeamleadId] = React.useState<string | number>("-1");
  const { date, type, handleDateSelectChange, dateDropdownList, setDate } =
    useDateFilter();
  const [packageSoldSum, setPackageSoldSum] = React.useState<number>(0);
  const [totalValue, setTotalValue] = React.useState(0);
  const navigate = useNavigate();
  const auth = useAuth();
  const [status, setStatus] = React.useState<"ACTIVE" | "PENDING">("ACTIVE");
  /**************************************************************/
  const [Officelist, setOfficeList] = React.useState<IBranchOffice>({
    result: [],
  });

  const [branchOffice, setBranchOffice] = React.useState<string>(
    auth.user.office ? auth.user.office : "-1"
  );
  const { list, loadState } = useSelector(
    (storeState: IStoreState) => storeState.common.reportee_referrals
  );
  const { showTopErrorSnackbar } = useMessagesPopup();

  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 25,
  });
  const [totalRecords, setTotalRecords] = React.useState(0);

  const [leadId, setLeadId] = React.useState<number | string>("-1");

  const totalValues = React.useMemo(() => {
    const totalAmount = ActivationList.reduce((prev, newValue) => {
      return prev + newValue.total_package_price;
    }, 0);
    return {
      sale: totalAmount
        .toLocaleString("en-IN")
        .replace(/^(\d+)(\d{2})(\d{3})$/, "$1,$2,$3"),
      memberCount: ActivationList.length,
    };
  }, [ActivationList, branchOffice]);

  /**************************************************************************************/

  const fetchOffice = async () => {
    if (auth.user && auth.user.tokens) {
      try {
        const res = await api.get(`/list-branch-offices`, {
          headers: {
            "auth-Token": auth.user.tokens.access,
          },
        });
        setOfficeList(res.data);
        // setLeadId(res.data.)
      } catch (err: any) {
        showTopErrorSnackbar(err.response.data.msg);
      } finally {
      }
    }
  };

  React.useEffect(() => {
    fetchOffice();
  }, []);
  /***************************************************************************************/
  const fetchActivationReport = async () => {
    if (auth.user && auth.user.tokens) {
      try {
        setLoading(true);
        let apiURL = `/sow-total-sales-table-report-by-team-lead?from_date=${date.fromDate}&to_date=${date.toDate}`;
        if (branchOffice !== "-1") {
          apiURL += `&branch_office=${branchOffice}`;
        }
        const res = await api.get(apiURL, {
          headers: {
            "auth-Token": auth.user.tokens.access,
          },
        });
        setActivationList(res.data.result);

        const packageSoldSum = res.data.result.reduce(
          (sum: any, item: { package_sold: any }) => sum + item.package_sold,
          0
        );
        setPackageSoldSum(packageSoldSum);

        const totalValue = res.data.result.reduce(
          (sum: any, item: { total_package_price: any }) =>
            sum + item.total_package_price,
          0
        );
        setTotalValue(totalValue);

        setTotalRecords(res.data.total.totalDataLength);
      } catch (err: any) {
        const errorMessage = err.response?.data?.result.msg;
        showTopErrorSnackbar(errorMessage);
      } finally {
        setLoading(false);
      }
    }
  };
  const handleNavigate = (reporting_lead_id: number, lead_name:string) => {
    navigate(`/sow-sales-by-teamlead/leadId=${reporting_lead_id}`, {
      state: {
        fromDate: date.fromDate as string,
        toDate: date.toDate as string,
        type: type,
        leadName:lead_name
      },
    });
  };

  React.useEffect(() => {
    fetchActivationReport();
  }, [TeamleadId, date, tablePagination, branchOffice]);

  const ActivationTableProps: IDataTableProps = {
    isPagination: true,
    isDataLoading: loading,
    totalRecords: totalRecords,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [
          {
            key: "branchOffice",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={branchOffice}
                onChange={(e) => setBranchOffice(e.target.value as string)}
                disabled={
                  auth.user.role !== userRoles.CXO &&
                  auth.user.role !== userRoles.FINANCE
                }
              >
                <MenuItem value="-1">All Offices</MenuItem>
                {Officelist.result.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </CustomSelect>
            ),
          },
          {
            key: "byDate",
            onRender: () => (
              <DateFilterDropdown
                value={type}
                options={dateDropdownList}
                onChange={handleDateSelectChange}
              />
            ),
          },
          {
            key: "fromDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "142px" }}
                  value={date.fromDate}
                  onChange={(newValue) => {
                    const newDate = moment(newValue).format("YYYY-MM-DD");
                    setDate({ ...date, fromDate: newDate });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "toDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "142px" }}
                  value={date.toDate}
                  onChange={(newValue) => {
                    const newDate = moment(newValue).format("YYYY-MM-DD");
                    setDate({ ...date, toDate: newDate });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "refresh",
            onRender: () => <RefreshPlugin onClick={fetchActivationReport} />,
          },

          {
            key: "csvExport",
            onRender: (columns, items) => (
              <CSVExportPlugin
                columns={columns}
                items={items}
                filePrefixName="Report"
              />
            ),
          },
        ],
      },
      rightItems: {
        plugins: {
          searchField: {
            searchKeys: ["user_name"],
            items: ActivationList,
          },
        },
      },
    },
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    columns: [
      {
        key: "lead_name",
        headerName: "TeamLead",
        fieldName: "lead_name",
        renderType: RenderType.TEXT,
        exportCellWidth: 35,
      },
      {
        key: "total_package_price",
        headerName: "Total Sales",
        fieldName: "total_package_price",
        renderType: RenderType.TEXT,
        exportCellWidth: 35,
        onRowCellValueRender: (value: string | number, row: any) => {
          if (typeof value === "number") {
            return value
              .toLocaleString()
              .replace(/^(\d+)(\d{2})(\d{3})$/, "$1,$2,$3");
          }
          return ""; // Return an empty string or any placeholder you prefer if value is undefined or not a number
        },
      },
      {
        key: "manager_name",
        headerName: "Reporting Manager",
        fieldName: "manager_name",
        renderType: RenderType.TEXT,
        exportCellWidth: 35,
      },
      {
        key: "actions",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row) => {
          return (
            <ContextMenu
              menuOptions={[
                {
                  icon: <AspectRatio />,
                  label: "See Full Report",
                  onClick: () => handleNavigate(row.reporting_lead_id, row.lead_name), //Check
                },
              ]}
            ></ContextMenu>
          );
        },
      },
    ],
    items: ActivationList,
    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
  };

  return (
    <PageContainer title="sales by TeamLead" description="this is innerpage">
      <Breadcrumb title="Sales under Teamlead Report" items={BCrumb} />

      <StandadCard heading="Total Sales Report">
        <Box
          sx={{
            padding: 2,
            borderRadius: 1,
            m: 2,
            backgroundColor: "background.default",
          }}
        >
          <CustomTypography variant="body1" sx={{ mb: 1 }}>
            Based on the filter values you have selected from the table, here
            are the results:
          </CustomTypography>

          <Grid container spacing={0}>
            <Grid item xs={12} md={2}>
              <CustomTypography variant="h5" fontWeight={"bold"}>
                TOTAL SALES
              </CustomTypography>
              <CustomTypography variant="h3" fontWeight={"bold"}>
                ₹ {totalValues.sale}
              </CustomTypography>
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomTypography variant="h5" fontWeight={"bold"}>
                COUNT
              </CustomTypography>
              <CustomTypography variant="h3" fontWeight={"bold"}>
                {totalValues.memberCount}
              </CustomTypography>
            </Grid>
          </Grid>
        </Box>

        <DataTable {...ActivationTableProps} />
      </StandadCard>
    </PageContainer>
  );
};
