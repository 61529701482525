import { action } from "typesafe-actions";
import { IProduct } from "./Product.types";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { rest } from "lodash";
import { useMessagesPopup } from "../../contexts/MessagesPopupProvider/MessagesPopupProvider";

export const FETCH_PRODUCT_LIST_PROGRESS = "FETCH_PRODUCT_LIST_PROGRESS";
export const FETCH_PRODUCT_LIST_SUCCESS = "FETCH_PRODUCT_LIST_SUCCESS";
export const FETCH_PRODUCT_LIST_FAILED = "FETCH_PRODUCT_LIST_FAILED";

export const fetchProductListProgress = () =>
  action(FETCH_PRODUCT_LIST_PROGRESS);

export const fetchProductListSuccess = (
  list: IProduct[],
  totalRecords: number
) => action(FETCH_PRODUCT_LIST_SUCCESS, { list, totalRecords });

export const fetchProductListFailed = () => action(FETCH_PRODUCT_LIST_FAILED);

export const fetchProductListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    date?: IDate,
    searchValue?: string,
    searchType?: string[]
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchProductListProgress());
      let url = "";
      if (status !== "-1") {
        url = "&status=" + status;
      }
      let finalUrl =
        `get-product?pageNo=${pageNumber}&itemsPerPage=${rowsInPerPage}` + url;

      const res = await api.get(finalUrl);
      const data: IProduct[] = res.data.result;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchProductListSuccess(data, totalRecords));
    } catch (error) {
      dispatch(fetchProductListFailed());
    }
  };

// fetch single product item

export const FETCH_PRODUCT_PROGRESS = "FETCH_PRODUCT_PROGRESS";
export const FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS";
export const FETCH_PRODUCT_FAILED = "FETCH_PRODUCT_FAILED";

export const fetchProductProgress = () => action(FETCH_PRODUCT_PROGRESS);

export const fetchProductSuccess = (list: IProduct) =>
  action(FETCH_PRODUCT_SUCCESS, { list });

export const fetchProductFailed = (errorMessage: string) =>
  action(FETCH_PRODUCT_FAILED, { errorMessage });

export const fetchProductAsync =
  (productId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchProductProgress());
      const res = await api.get(`/get-product?product_code=${productId}`);
      const data: IProduct[] = res.data.result;
      if (data.length > 0) {
        dispatch(fetchProductSuccess(data[0]));
      } else {
        dispatch(fetchProductFailed("Oops! We couldn't find any records."));
      }
    } catch (error) {
      dispatch(fetchProductFailed("Something went to be wrong!"));
    }
  };

// upsert product item

export const upsertProductAsync =
  (
    existingProduct: IProduct,
    onCallback: (isSuccess: boolean, data?: IProduct) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const { showTopSuccessSnackbar, showTopErrorSnackbar } = useMessagesPopup();

    try {
      const { create_dt, insert_dt, ...rest } = existingProduct;
      const res = await api.post("/create-product", rest);
      const data = res.data.result;
      onCallback(true, data);
      showTopSuccessSnackbar("Product created successfully");
    } catch (error) {
      showTopErrorSnackbar("Something went to be wrong!");
    } finally {
    }
  };

export const CLEAR_PRODUCT = "CLEAR_PRODUCT";
export const CLEAR_PRODUCT_STATE = "CLEARPRODUCTT_STATE";
export const clearProduct = () => action(CLEAR_PRODUCT);
export const clearProductState = () => action(CLEAR_PRODUCT_STATE);
