import {
  AspectRatio,
  Check,
  Download,
  PendingActions,
} from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import { Box, MenuItem, SelectChangeEvent } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { api } from "../../api/api";
import { StandadCard } from "../../components/Cards";
import { PageContainer } from "../../components/container/PageContainer";
import { DataTable } from "../../components/Table/DataTable";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../components/Table/interfaces/IDataTableProps";
import { CSVExportPlugin } from "../../components/Table/plugins";
import { RefreshPlugin } from "../../components/Table/plugins";
import { useAuth } from "../../contexts/AuthProvider/AuthProvider";
import { useMessagesPopup } from "../../contexts/MessagesPopupProvider/MessagesPopupProvider";
import { truncate } from "../../helpers";
import { Breadcrumb } from "../../layout/fullLayout/breadcrumb/Breadcrumb";
import { IBreadcrumbProps } from "../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { ViewManualActivation } from "../memberActivation/components/ViewMannualActivation/ViewManualActivation";
import { IManuallyCreatedMember } from "../memberActivation/interfaces/IMemberActivation";
import { ContextMenu } from "../../components/ContextMenu/ContextMenu";
import moment from "moment";
import { CustomDatePicker } from "../../components/formsComponents/CustomPicker";
import { MembershipCard } from "../../components/MembershipCard/MembershipCard";

import { useDateFilter } from "../../components/Table/hooks/useDateFilter";
import { DateFilterDropdown } from "../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { FEActivationPendingReport } from "./FEActivationPendingReport";
import { CustomSelect } from "../../components/formsComponents";

export const FEActivationsReport: React.FC = () => {
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/",
      title: "Dashboard",
    },
    {
      title: "activations-report",
    },
  ];

  const { date, type, handleDateSelectChange, dateDropdownList, setDate } =
    useDateFilter();

  const historyList = React.useRef<IManuallyCreatedMember[]>([]);
  const [list, setList] = React.useState<IManuallyCreatedMember[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [openView, setOpenView] = React.useState<IManuallyCreatedMember | null>(
    null
  );
  const [card, setCard] = React.useState<IManuallyCreatedMember | null>(null);
  const { showTopErrorSnackbar } = useMessagesPopup();
  const auth = useAuth();
  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 25,
  });
  const [totalRecords, setTotalRecords] = React.useState(0);

  const [filterStatus, setFilterStatus] = React.useState("true");

  const fetchList = async () => {
    if (auth.user && auth.user.tokens) {
      try {
        setLoading(true);
        let apiURL;
        if (filterStatus === "false") {
          apiURL = `/pending-list-for-activation?from_date=${date.fromDate}&to_date=${date.toDate}&page=${tablePagination.pageNumber}&size=${tablePagination.rowsInPerPage}`;
        } else {
          apiURL = `/active-user-list-of-manual-activation?from_date=${date.fromDate}&to_date=${date.toDate}&page=${tablePagination.pageNumber}&size=${tablePagination.rowsInPerPage}`;
        }

        const res = await api.get(apiURL, {
          headers: {
            "auth-Token": auth.user.tokens.access,
          },
        });
        setList(res.data.result);
        setTotalRecords(res.data.total.totalDataLength);
      } catch (err: any) {
        showTopErrorSnackbar(err.response.data.msg);
      } finally {
        setLoading(false);
      }
    }
  };

  React.useEffect(() => {
    fetchList();
  }, [date, filterStatus, tablePagination]);

  const activationTableProps: IDataTableProps = {
    isPagination: true,
    isDataLoading: loading,
    totalRecords: totalRecords,
    rowsPerPageOptions: tablePagination.rowsInPerPage,
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [
          {
            key: "filterStatus",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={filterStatus}
                onChange={(e) => setFilterStatus(e.target.value as string)}
              >
                <MenuItem value="true">Activated List</MenuItem>
                <MenuItem value="false">Pending List</MenuItem>
              </CustomSelect>
            ),
          },
          {
            key: "byDate",
            onRender: () => (
              <DateFilterDropdown
                value={type}
                options={dateDropdownList}
                onChange={handleDateSelectChange}
              />
            ),
          },
          {
            key: "fromDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "142px" }}
                  value={date.fromDate}
                  onChange={(newValue) => {
                    const newDate = moment(newValue).format("YYYY-MM-DD");
                    setDate({ ...date, fromDate: newDate });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "toDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "142px" }}
                  value={date.toDate}
                  onChange={(newValue) => {
                    const newDate = moment(newValue).format("YYYY-MM-DD");
                    setDate({ ...date, toDate: newDate });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "refresh",
            onRender: () => (
              <RefreshPlugin
                onClick={() => {
                  fetchList();
                }}
              />
            ),
          },
          {
            key: "csvExport",
            onRender: (columns, items) => (
              <CSVExportPlugin
                columns={columns}
                items={items}
                filePrefixName="Report"
              />
            ),
          },
        ],
      },
      rightItems: {
        plugins: {
          searchField: {
            searchKeys: [
              "user_prospect_id",
              "user_name",
              "contact_no",
              "email_id",
              "state",
              "city",
              "area",
              "address",
              "vehicle_no",
              "make_name",
              "model_name",
              "registration_year",
              "package_name",
              "comment",
              "promo_code",
              "referral_code",
              "status",
              "partner_id",
              "payment_mode",
              "amount",
              "membership_code",
            ],
            items: list,
          },
        },
      },
    },
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    columns: [
      {
        key: "actions",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row) => {
          const menuItems = [
            {
              icon: <AspectRatio />,
              label: "See Details",
              onClick: () => setOpenView(row),
            },
          ];
          if (row.membership_code) {
            menuItems.push({
              icon: <Download />,
              label: "Download Card",
              onClick: () => {
                setCard(row);
              },
            });
          }

          return <ContextMenu menuOptions={menuItems}></ContextMenu>;
        },
      },
      {
        key: "created_at",
        headerName: "Date",
        fieldName: "created_at",
        exportCellWidth: 10,
        renderType: RenderType.DATE,
      },
      {
        key: "user_prospect_id",
        headerName: "Prospect ID",
        fieldName: "user_prospect_id",
        exportCellWidth: 10,
        renderType: RenderType.TEXT,
      },
      {
        key: "vehicle_no",
        headerName: "Vehicle No.",
        fieldName: "vehicle_no",
        exportCellWidth: 15,
        renderType: RenderType.TEXT,
      },
      {
        key: "name",
        headerName: "Name",
        fieldName: "user_name",
        exportCellWidth: 15,
        renderType: RenderType.TEXT,
      },

      {
        key: "contact_no",
        headerName: "Contact",
        fieldName: "contact_no",
        exportCellWidth: 15,
        renderType: RenderType.TEXT,
      },
      {
        key: "membership_code",
        headerName: "Membership Code",
        fieldName: "membership_code",
        exportCellWidth: 15,
        renderType: RenderType.TEXT,
        isHidden: true,
      },

      {
        key: "package_name",
        headerName: "Plan",
        fieldName: "package_name",
        renderType: RenderType.TEXT,
        exportCellWidth: 20,
        onRowCellValueRender: (value) => {
          return truncate(value, 10);
        },
      },
      {
        key: "amount",
        headerName: "Amount",
        fieldName: "amount",
        exportCellWidth: 15,
        renderType: RenderType.TEXT,
        isHidden: true,
      },
      // {
      //   key: "office_name",
      //   headerName: "Office Name",
      //   fieldName: "office_name",
      //   exportCellWidth: 15,
      //   renderType: RenderType.TEXT,
      //   // isHidden: true,
      // },
      {
        key: "payment_mode",
        headerName: "Payment Mode",
        fieldName: "payment_mode",
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
      },
      {
        key: "make_name",
        headerName: "Brand",
        fieldName: "make_name",
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
        isHidden: true,
      },
      {
        key: "model_name",
        headerName: "Model",
        fieldName: "model_name",
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
        isHidden: true,
      },
      {
        key: "fe_name",
        headerName: "FE Name",
        fieldName: "fe_name",
        renderType: RenderType.TEXT,
        exportCellWidth: 15,
        isHidden: true,
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        exportCellWidth: 25,
        renderType: RenderType.TEXT,
        onRowCellRender: (value, row) => {
          return (
            <Box>
              {value.value === "ACTIVE" ? (
                <Tooltip title="ACTIVE">
                  <Check color="success" />
                </Tooltip>
              ) : (
                <Tooltip title="Pending">
                  <PendingActions color="warning" />
                </Tooltip>
              )}
            </Box>
          );
        },
      },
    ],
    items: list,
    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
  };

  return (
    <PageContainer
      title="Activations by Field Executives"
      description="this is innerpage"
    >
      <Breadcrumb
        title="Activations by Field Executives Report"
        items={BCrumb}
      />

      <StandadCard heading="Manually Activated Users">
        <DataTable {...activationTableProps} />
        {openView && (
          <ViewManualActivation
            open={true}
            activation={openView}
            onClose={() => setOpenView(null)}
          />
        )}
        {card && (
          <MembershipCard
            download={true}
            cardInfo={{
              customerName: card.user_name,
              membershipCode: card.membership_code || "",
              planName: card.package_name,
              vehicleNumber: card.vehicle_no,
            }}
            onDownloadComplete={() => setCard(null)}
          />
        )}
      </StandadCard>
    </PageContainer>
  );
};
