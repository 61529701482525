import produce from "immer";
import { CallReportActions, CallReportDetailsActions, FETCH_CALL_REPORT_DATA_FAILED, FETCH_CALL_REPORT_DATA_PROGRESS, FETCH_CALL_REPORT_DATA_SUCCESS, FETCH_CALL_REPORT_DETAILS_FAILED, FETCH_CALL_REPORT_DETAILS_PROGRESS, FETCH_CALL_REPORT_DETAILS_SUCCESS } from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";

import { defaultCallReportListState, defaultCallReportValueState } from "./defaultState";

export const callReportReducer = (
    state: IStoreState["callReport"] = defaultCallReportListState,
    action: CallReportActions
  ) => {
    switch (action.type) {
      case FETCH_CALL_REPORT_DATA_PROGRESS: {
        const newState = produce(state, (draftState: any) => {
          draftState.list.loading = LoadState.InProgress;
          draftState.list.data = [];
         
        });
        return newState;
      }
      case FETCH_CALL_REPORT_DATA_SUCCESS: {
        const { list } = action.payload;
        const newState = produce(state, (draftState: any) => {
          draftState.list.loading = LoadState.Loaded;
          draftState.list.data = list;
         
          
        });
        return newState;
      }
      case FETCH_CALL_REPORT_DATA_FAILED: {
        const newState = produce(state, (draftState: any) => {
          draftState.list.loading = LoadState.InProgress;
          draftState.list.data = [];
         
        });
        return newState;
      }
     
     
      default: {
        return state;
      }
    }
  };

  export const callReportDetailsReducer = (
    state: IStoreState["callReportValue"] = defaultCallReportValueState,
    action: CallReportDetailsActions
  ) => {
    switch (action.type) {
      case FETCH_CALL_REPORT_DETAILS_PROGRESS: {
        const newState = produce(state, (draftState: any) => {
          draftState.list.loading = LoadState.InProgress;
          draftState.list.data = [];
         
        });
        return newState;
      }
      case FETCH_CALL_REPORT_DETAILS_SUCCESS: {
        const { list, totalRecords } = action.payload;
        const newState = produce(state, (draftState: any) => {
          draftState.list.loading = LoadState.Loaded;
          draftState.list.data = list;
          draftState.list.totalRecords = totalRecords;
          
        });
        return newState;
      }
      case FETCH_CALL_REPORT_DETAILS_FAILED: {
        const newState = produce(state, (draftState: any) => {
          draftState.list.loading = LoadState.InProgress;
          draftState.list.data = [];
         
        });
        return newState;
      }
     
     
      default: {
        return state;
      }
    }
  };