import produce from "immer";
import { PromoActions } from ".";
import { LoadState } from "../../constants/enums";
import {
  FETCH_PROMO_LIST_FAILURE,
  FETCH_PROMO_LIST_PROGRESS,
  FETCH_PROMO_LIST_SUCCESS,
} from "../constants";
import { IStoreState } from "../initialStoreState";
import { initialPromoState } from "./types";

export const PromoReducer = (
  state: IStoreState["promo"] = initialPromoState,
  action: PromoActions
) => {
  switch (action.type) {
    case FETCH_PROMO_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loadState = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_PROMO_LIST_SUCCESS: {
      const newState = produce(state, (draftState) => {
        draftState.PromoCode = action.payload.promo;
        draftState.loadState = LoadState.Loaded;
      });
      return newState;
    }
    case FETCH_PROMO_LIST_FAILURE: {
      const newState = produce(state, (draftState) => {
        draftState.loadState = LoadState.Failed;
      });
      return newState;
    }

    default: {
      return state;
    }
  }
};
