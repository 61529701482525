import { Box, CircularProgress, Dialog, Typography } from "@mui/material";
import React from "react";
// import { Dialog } from "../Dialogs/Dialog";

interface DownloadPopupProps {
  open: boolean;
  percentage: number;
  onClose: () => void;
}

export const DownloadPopup: React.FC<DownloadPopupProps> = ({
  open,
  percentage,
  onClose,
}) => {
  if (!open) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      BackdropProps={{
        style: { backgroundColor: "rgba(0, 0, 0, 0.5)" }, // 50% transparent black backdrop
      }}
      PaperProps={{
        sx: {
          backgroundColor: "rgba(16, 16, 16, 0.5)", // 50% transparent dialog background
          boxShadow: "none", // Remove shadow
        },
      }}
    >
      <Box margin="auto">
        {" "}
        <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
          {" "}
          <CircularProgress />{" "}
          <Typography variant="h6" textAlign={"center"} color={"#fff"}>
            Downloading Membership Card...
          </Typography>
        </Box>
      </Box>
    </Dialog>
  );
};
