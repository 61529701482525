import { LoadState } from "../../constants/enums";
import { ICallReport, ICallReportState, ICallReportValue, ICallReportValueState } from "./CallReport.types";

export const defaultCallReportValues:ICallReportValue={
    call_campaign_id: 0,
    member_id: "",
    customer_name: "",
    vehicle_no: "",
    phone_res: "",
    phone_off: "",
    mobile_number: "",
    mobile_secondary: null,
    email_id: "",
    registration_year: null,
    membership_start_date: "",
    membership_end_date: "",
    city: null,
    state: null,
    address: "",
    pin: null,
    next_day_call: "",
    next_day_call_time: null,
    dnd_flag: 0,
    interested_plan: "",
    suggested_plan: "",
    interested_flag: "",
    call_disposition: "",
    category: "",
    campaign_name: "",
    scheduled_call_date: "",
    assign_to_id: 0,
    assign_to_name: "",
    lead_id: 0,
    lead_name: "",
    status: "",
    comment: "",
    create_dt: "",
    update_dt: "",
    insert_dt: "",
}

export const defaultCallReport:ICallReport={
 lead_name: "",
            lead_id: 0,
            call_disposition: "",
            disposition_count: 0,
        assign_to_name: "",
        assign_to_id: 0}

export const defaultCallReportListState:ICallReportState={
list:{
    data: [],
    loading: LoadState.NotLoaded,
    error: null
}
}

export const defaultCallReportValueState:ICallReportValueState = {
    list:{
        data: [],
        totalRecords: 0,
        loading: LoadState.NotLoaded,
        error: null
    }
}