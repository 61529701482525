import produce from "immer";
import {
  CLEAR_PRODUCT,
  FETCH_PRODUCT_FAILED,
  FETCH_PRODUCT_LIST_FAILED,
  FETCH_PRODUCT_LIST_PROGRESS,
  FETCH_PRODUCT_LIST_SUCCESS,
  FETCH_PRODUCT_PROGRESS,
  FETCH_PRODUCT_SUCCESS,
  ProductActions,
} from ".";
import { IStoreState } from "../initialStoreState";
import { defaultProduct, defaultProductState } from "./defaultState";
import { LoadState } from "../../constants/enums";

export const productReducer = (
  state: IStoreState["product"] = defaultProductState,
  action: ProductActions
) => {
  switch (action.type) {
    case FETCH_PRODUCT_LIST_PROGRESS: {
      const newState = produce(state, (draftState: any) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_PRODUCT_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = list;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_PRODUCT_LIST_FAILED: {
      const newState = produce(state, (draftState: any) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_PRODUCT_PROGRESS: {
      const newState = produce(state, (draftState: any) => {
        draftState.product.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_PRODUCT_SUCCESS: {
      const { list } = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.product.loading = LoadState.Loaded;
        draftState.product.data = list;
      });
      return newState;
    }
    case FETCH_PRODUCT_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.product.loading = LoadState.Failed;
        draftState.product.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_PRODUCT: {
      const newState = produce(state, (draftState: any) => {
        draftState.product.loading = LoadState.NotLoaded;
        draftState.product.data = defaultProduct;
      });
      return newState;
    }
    default: {
      return state;
    }
  }
};
