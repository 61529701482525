import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { LoadState } from "../../constants/enums";
import { getUserAuth, getUserAuthDetails } from "../../helpers";
import {
  FETCH_CITY_FAILURE,
  FETCH_CITY_PROGRESS,
  FETCH_CITY_SUCCESS,
  FETCH_FAULT_NAME_FAILURE,
  FETCH_FAULT_NAME_PROGRESS,
  FETCH_FAULT_NAME_SUCCESS,
  FETCH_FAULT_SUB_TYPE_FAILURE,
  FETCH_FAULT_SUB_TYPE_PROGRESS,
  FETCH_FAULT_SUB_TYPE_SUCCESS,
  FETCH_FAULT_TYPE_FAILURE,
  FETCH_FAULT_TYPE_PROGRESS,
  FETCH_FAULT_TYPE_SUCCESS,
  FETCH_MECHANIC_FAILURE,
  FETCH_MECHANIC_PROGRESS,
  FETCH_MECHANIC_SUCCESS,
  FETCH_PACKAGES_PROGRESS,
  FETCH_PACKAGES_SUCCESS,
  FETCH_STATE_FAILURE,
  FETCH_STATE_PROGRESS,
  FETCH_STATE_SUCCESS,
  FETCH_USER_ROLES_FAILURE,
  FETCH_USER_ROLES_PROGRESS,
  FETCH_USER_ROLES_SUCCESS,
  FETCH_PACKAGES_FAILURE,
  FETCH_MAKER_PROGRESS,
  FETCH_MAKER_SUCCESS,
  FETCH_MAKER_FAILURE,
  FETCH_MODEL_PROGRESS,
  FETCH_MODEL_SUCCESS,
  FETCH_MODEL_FAILURE,
  FETCH_SALES_LEADS_PROGRESS,
  FETCH_SALES_LEADS_SUCCESS,
  FETCH_SALES_LEADS_FAILURE,
  FETCH_SFA_PROGRESS,
  FETCH_SFA_SUCCESS,
  FETCH_SFA_FAILURE,
  FETCH_MANAGERS_PROGRESS,
  FETCH_MANAGERS_SUCCESS,
  FETCH_MANAGERS_FAILURE,
  FETCH_REFERRALS_FAILURE,
  FETCH_REFERRALS_PROGRESS,
  FETCH_REFERRALS_SUCCESS,
  FETCH_REPORTEE_REFERRALS_FAILURE,
  FETCH_REPORTEE_REFERRALS_PROGRESS,
  FETCH_REPORTEE_REFERRALS_SUCCESS,
  FETCH_BRANCH_OFFICE_FAILURE,
  FETCH_BRANCH_OFFICE_PROGRESS,
  FETCH_BRANCH_OFFICE_SUCCESS,
  FETCH_DUAL_MAKER_SUCCESS,
  FETCH_DUAL_MAKER_FAILURE,
  FETCH_DUAL_MAKER_PROGRESS,
} from "../constants";
import { IStoreState } from "../initialStoreState";
import {
  ICity,
  ICommon,
  ILead,
  IManager,
  IPackage,
  ISelect,
  ISFA,
  IState,
  IReferrals,
  ISalesManager,
  vehicleType,
  IReporteeReferrals,
  IBranchOffice,
} from "./types";
import { IAssignReferalProps } from "../../views/dashboard/CXODashboard/components/AssignReferal/interfaces/IAssignReferalProps";
import { userRoles } from "../../contexts/AuthProvider/AuthProvider";

export const fetchRolesProgress = () => action(FETCH_USER_ROLES_PROGRESS);
export const fetchRolesSuccess = (roles: ICommon["roles"]["list"]) =>
  action(FETCH_USER_ROLES_SUCCESS, { roles });
export const fetchRolesError = () => action(FETCH_USER_ROLES_FAILURE);

export const fetchUserRolesAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const loadState = getState().common.roles.loadState;
    const token = getUserAuth();
    try {
      if (loadState === LoadState.NotLoaded && token) {
        dispatch(fetchRolesProgress());
        const res = await api.get("/list-roles");
        dispatch(fetchRolesSuccess(res.data.result));
      }
    } catch (err) {
      dispatch(fetchRolesError());
    }
  };

export const fetchFaultTypeProgress = () => action(FETCH_FAULT_TYPE_PROGRESS);
export const fetchFaultTypeSuccess = (key: string, data: ISelect[]) =>
  action(FETCH_FAULT_TYPE_SUCCESS, { key, data });
export const fetchFaultTypeError = () => action(FETCH_FAULT_TYPE_FAILURE);

export const fetchFaultTypeAsync =
  (
    vehicleType: "FOUR_WHEELER" | "TWO_WHEELER"
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const loadState = getState().common.roles.loadState;
    const state = getState().common.faultType;
    const token = getUserAuth();
    try {
      if (
        loadState === LoadState.NotLoaded &&
        token &&
        !state.list[vehicleType]
      ) {
        dispatch(fetchFaultTypeProgress());
        const res = await api.get(
          `/get-fault-type?vehicle_type=${vehicleType}`
        );
        const list: ISelect[] = [];
        for (let item of res.data) {
          list.push({ label: item.fault_type, value: item.fault_type });
        }
        dispatch(fetchFaultTypeSuccess(vehicleType, list));
      }
    } catch (err) {
      dispatch(fetchFaultTypeError());
    }
  };

export const fetchFaultNameProgress = () => action(FETCH_FAULT_NAME_PROGRESS);
export const fetchFaultNameSuccess = (key: string, data: ISelect[]) =>
  action(FETCH_FAULT_NAME_SUCCESS, { key, data });
export const fetchFaultNameError = () => action(FETCH_FAULT_NAME_FAILURE);

export const fetchFaultNameAsync =
  (
    vehicleType: vehicleType,
    faultType: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const loadState = getState().common.roles.loadState;
    const state = getState().common.faultName;
    const key = vehicleType + "-" + faultType;
    const token = getUserAuth();
    try {
      if (loadState === LoadState.NotLoaded && token && !state.list[key]) {
        dispatch(fetchFaultNameProgress());
        const res = await api.get(
          `/get-fault-name?vehicle_type=${vehicleType}&fault_type=${faultType}`
        );
        const list: ISelect[] = [];
        for (let item of res.data) {
          list.push({ label: item.fault_name, value: item.fault_name });
        }
        dispatch(fetchFaultNameSuccess(key, list));
      }
    } catch (err) {
      dispatch(fetchFaultNameError());
    }
  };

export const fetchFaultSubTypeProgress = () =>
  action(FETCH_FAULT_SUB_TYPE_PROGRESS);
export const fetchFaultSubTypeSuccess = (key: string, data: ISelect[]) =>
  action(FETCH_FAULT_SUB_TYPE_SUCCESS, { key, data });
export const fetchFaultSubTypeError = () =>
  action(FETCH_FAULT_SUB_TYPE_FAILURE);

export const fetchFaultSubTypeAsync =
  (
    vehicleType: vehicleType,
    faultType: string,
    faultName: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const loadState = getState().common.roles.loadState;
    const state = getState().common.faultName;
    const key = vehicleType + "-" + faultType + "-" + faultName;
    const token = getUserAuth();
    try {
      if (loadState === LoadState.NotLoaded && token && !state.list[key]) {
        dispatch(fetchFaultSubTypeProgress());
        const res = await api.get(
          `/get-sub-fault-name?vehicle_type=${vehicleType}&fault_type=${faultType}&fault_name=${faultName}`
        );
        const list: ISelect[] = [];
        for (let item of res.data) {
          list.push({ label: item.sub_fault_name, value: item.sub_fault_name });
        }
        dispatch(fetchFaultSubTypeSuccess(key, list));
      }
    } catch (err) {
      dispatch(fetchFaultSubTypeError());
    }
  };

export const fetchMechanicProgress = () => action(FETCH_MECHANIC_PROGRESS);
export const fetchMechanicSuccess = (mechanics: ICommon["mechanics"]["list"]) =>
  action(FETCH_MECHANIC_SUCCESS, { mechanics });
export const fetchMechanicError = () => action(FETCH_MECHANIC_FAILURE);

export const fetchMechanicAsync =
  (
    page?: number,
    size?: number
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const loadState = getState().common.mechanics.loadState;
    const token = getUserAuth();
    try {
      if (loadState === LoadState.NotLoaded && token) {
        dispatch(fetchMechanicProgress());
        const res = await api.get(
          `/get-mechanic-list?&pageNo=${page}&itemsPerPage=${size}`,
          {
            headers: {
              "auth-Token": token,
            },
          }
        );
        //set api response data in store
        dispatch(fetchMechanicSuccess(res.data.result));
      }
    } catch (err) {
      dispatch(fetchRolesError());
    }
  };

export const fetchStateProgress = () => action(FETCH_STATE_PROGRESS);
export const fetchStateSuccess = (data: IState[]) =>
  action(FETCH_STATE_SUCCESS, { data });
export const fetchStateError = () => action(FETCH_STATE_FAILURE);

export const fetchStateAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const loadState = getState().common.state.loadState;
    const token = getUserAuth();
    try {
      if (loadState === LoadState.NotLoaded && token) {
        dispatch(fetchStateProgress());
        const res = await api.get(`/get-state`);

        dispatch(fetchStateSuccess(res.data.data));
      }
    } catch (err) {
      dispatch(fetchStateError());
    }
  };

export const fetchCityProgress = () => action(FETCH_CITY_PROGRESS);
export const fetchCitySuccess = (key: number, data: ICity[]) =>
  action(FETCH_CITY_SUCCESS, { key, data });
export const fetchCityError = () => action(FETCH_CITY_FAILURE);

export const fetchCityAsync =
  (stateId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const cityData = getState().common.city;
    const key = stateId;
    const token = getUserAuth();

    try {
      if (!cityData.list[key] && token) {
        dispatch(fetchCityProgress());
        const res = await api.get(`/get-city?state_id=${stateId}`);

        dispatch(fetchCitySuccess(key, res.data.data));
      }
    } catch (err) {
      dispatch(fetchCityError());
    }
  };

export const fetchPackagesProgress = () => action(FETCH_PACKAGES_PROGRESS);
export const fetchPackagesSuccess = (data: IPackage[]) =>
  action(FETCH_PACKAGES_SUCCESS, { data });
export const fetchPackagesError = () => action(FETCH_PACKAGES_FAILURE);

export const fetchPackagesAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const loadState = getState().common.packages.loadState;
    const token = getUserAuth();

    try {
      if (loadState !== LoadState.Loaded && token) {
        dispatch(fetchPackagesProgress());
        const res = await api.get(`/get-packages?vehicle_type=Four_Wheeler`);

        dispatch(fetchPackagesSuccess(res.data.data));
      }
    } catch (err) {
      dispatch(fetchPackagesError());
    }
  };

export const fetchBrandsProgress = () => action(FETCH_MAKER_PROGRESS);
export const fetchBrandsSuccess = (data: string[]) =>
  action(FETCH_MAKER_SUCCESS, { data });
export const fetchBrandsError = () => action(FETCH_MAKER_FAILURE);

export const fetchBrandsAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const loadState = getState().common.brands.loadState;
    const token = getUserAuth();

    try {
      if (loadState !== LoadState.Loaded && token) {
        dispatch(fetchBrandsProgress());
        const res = await api.get(`/get-maker?vehicle_type=FOUR_WHEELER`);

        dispatch(fetchBrandsSuccess(res.data.data));
      }
    } catch (err) {
      dispatch(fetchBrandsError());
    }
  };

// ==================================== brands with two wheeler==================

export const fetchDualBrandsProgress = () => action(FETCH_DUAL_MAKER_PROGRESS);
export const fetchDualBrandsSuccess = (data: string[]) =>
  action(FETCH_DUAL_MAKER_SUCCESS, { data });
export const fetchDualBrandsError = () => action(FETCH_DUAL_MAKER_FAILURE);

export const fetchDualBrandsAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const loadState = getState().common.brands.loadState;
    const token = getUserAuth();

    try {
      if (loadState !== LoadState.Loaded && token) {
        dispatch(fetchBrandsProgress());
        const res = await api.get(`/get-maker?vehicle_type=FOUR_WHEELER`);

        let brands = res.data.data;

        // Manually add "Two Wheeler" to the list of brands
        brands = [...brands, "Two Wheeler"];

        dispatch(fetchBrandsSuccess(brands));
      }
    } catch (err) {
      dispatch(fetchBrandsError());
    }
  };

export const fetchModelsProgress = () => action(FETCH_MODEL_PROGRESS);
export const fetchModelsSuccess = (brandName: string, data: string[]) =>
  action(FETCH_MODEL_SUCCESS, { brandName, data });
export const fetchModelsError = () => action(FETCH_MODEL_FAILURE);

export const fetchModelsAsync =
  (brandName: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const models = getState().common.models;
    const token = getUserAuth();

    try {
      if (!models.list[brandName] && token) {
        dispatch(fetchModelsProgress());
        const res = await api.get(
          `/get-model?vehicle_type=FOUR_WHEELER&maker_name=${brandName}`
        );

        dispatch(fetchModelsSuccess(brandName, res.data.data));
      }
    } catch (err) {
      dispatch(fetchModelsError());
    }
  };

export const fetchSalesLeadsProgress = () => action(FETCH_SALES_LEADS_PROGRESS);
export const fetchSalesLeadsSuccess = (data: ISelect[]) =>
  action(FETCH_SALES_LEADS_SUCCESS, { data });
export const fetchSalesLeadsError = () => action(FETCH_SALES_LEADS_FAILURE);

export const fetchSalesLeadsAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const salesLeads = getState().common.salesLeads;
    const token = getUserAuth();

    try {
      if (salesLeads.loadState !== LoadState.Loaded && token) {
        dispatch(fetchSalesLeadsProgress());
        const res = await api.get(`/list-sales-team-lead`, {
          headers: {
            "auth-Token": token,
          },
        });

        const leadsList: ILead[] = res.data.result;

        const list: ISelect[] = [];
        for (let item of leadsList) {
          list.push({ label: item.user_name, value: item.user_id });
        }

        dispatch(fetchSalesLeadsSuccess(list));
      }
    } catch (err) {
      dispatch(fetchSalesLeadsError());
    }
  };

export const fetchSFAProgress = () => action(FETCH_SFA_PROGRESS);
export const fetchSFASuccess = (data: ISelect[]) =>
  action(FETCH_SFA_SUCCESS, { data });
export const fetchSFAError = () => action(FETCH_SFA_FAILURE);

export const fetchSFAAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const sfa = getState().common.sfa;
    const token = getUserAuth();

    try {
      if (sfa.loadState !== LoadState.Loaded && token) {
        dispatch(fetchSFAProgress());
        const res = await api.get(`/get-sfa`, {
          headers: {
            "auth-Token": token,
          },
        });

        const leadsList: ISFA[] = res.data;

        const list: ISelect[] = [];
        for (let item of leadsList) {
          list.push({ label: item.user_name, value: item.backoffice_users_id });
        }

        dispatch(fetchSFASuccess(list));
      }
    } catch (err) {
      dispatch(fetchSFAError());
    }
  };

export const fetchManagersProgress = () => action(FETCH_MANAGERS_PROGRESS);
export const fetchManagersSuccess = (data: ISelect[]) =>
  action(FETCH_MANAGERS_SUCCESS, { data });
export const fetchManagersError = () => action(FETCH_MANAGERS_FAILURE);

export const fetchManagersAsync =
  (userId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const token = getUserAuth();

    try {
      if (token) {
        dispatch(fetchManagersProgress());
        const res = await api.get(`/list-reporting-manager?user_id=${userId}`, {
          headers: {
            "auth-Token": token,
          },
        });

        const leadsList: IManager[] = res.data.result;

        const list: ISelect[] = [];
        for (let item of leadsList) {
          list.push({ label: item.user_name, value: item.backoffice_users_id });
        }

        dispatch(fetchManagersSuccess(list));
      }
    } catch (err) {
      dispatch(fetchSFAError());
    }
  };

/***************************************************************** */

export const fetchReferralsProgress = () => action(FETCH_REFERRALS_PROGRESS);
export const fetchReferralsSuccess = (data: ISelect[]) =>
  action(FETCH_REFERRALS_SUCCESS, { data });
export const fetchError = () => action(FETCH_REFERRALS_FAILURE);

export const fetchReferralsAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const token = getUserAuth();

    try {
      if (token) {
        dispatch(fetchReferralsProgress());
        const res = await api.get(`/referrals`, {
          headers: {
            "auth-Token": token,
          },
        });

        const leadsList: IReferrals[] = res.data.result;

        const list: ISelect[] = [];
        for (let item of leadsList) {
          //Need to check  for the values
          list.push({ label: item.user_name, value: item.referral_code });
        }

        dispatch(fetchReferralsSuccess(list));
      }
    } catch (err) {
      dispatch(fetchSFAError());
    }
  };

/**************************NEW*************************************** */

export const fetchReporteeReferralsProgress = () =>
  action(FETCH_REPORTEE_REFERRALS_PROGRESS);
export const fetchReporteeReferralsSuccess = (data: IReporteeReferrals[]) =>
  action(FETCH_REPORTEE_REFERRALS_SUCCESS, { data });
export const fetchErrorAny = () => action(FETCH_REPORTEE_REFERRALS_FAILURE);

export const fetchReporteeReferralsAsync =
  (managerId?: number | null): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const userDetails = getUserAuthDetails();

    try {
      let newParams = "";
      if (userDetails && userDetails.tokens) {
        const userId = userDetails.user_id;
        dispatch(fetchReporteeReferralsProgress());

        if (userDetails.role !== userRoles.CXO) {
          const id = managerId ?? userId;
          newParams = "reporting_manager_id=" + id;
        }
        const res = await api.get(`/get-reportee?${newParams}`, {
          headers: {
            "auth-Token": userDetails.tokens.access,
          },
        });

        const leadsList: IReporteeReferrals[] = res.data;

        console.log(leadsList);

        dispatch(fetchReporteeReferralsSuccess(leadsList));
      }
    } catch (err) {
      dispatch(fetchSFAError());
    }
  };

export const fetchBranchOfficeProgress = () =>
  action(FETCH_BRANCH_OFFICE_PROGRESS);
export const fetchBranchOfficeSuccess = (data: IBranchOffice[]) =>
  action(FETCH_BRANCH_OFFICE_SUCCESS, { data });
export const fetchOfficeError = () => action(FETCH_BRANCH_OFFICE_FAILURE);

export const fetchBranchOfficeAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const userDetails = getUserAuthDetails();
    if (userDetails && userDetails.tokens) {
      try {
        let newParams = "";
        dispatch(fetchBranchOfficeProgress());
        const res = await api.get(`/list-branch-offices`, {
          headers: {
            "auth-Token": userDetails.tokens.access,
          },
        });

        const OfficeList: IBranchOffice[] = res.data;

        dispatch(fetchBranchOfficeSuccess(OfficeList));
      } catch (err) {
        dispatch(fetchOfficeError());
      }
    }
  };

// const fetchOffice = async () => {
//   if (user.user && user.user.tokens) {
//     try {

//       const res = await api.get(`/list-branch-offices`, {
//         headers: {
//           "auth-Token": user.user.tokens.access,
//         },
//       });
//       setList(res.data);

//     } catch (err: any) {
//       showTopErrorSnackbar(err.response.data.msg);
//     } finally {
//     }
//   }
// };
