import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Box } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { CustomTypography } from "../formsComponents";
import { Dialog } from "./Dialog";
import { IAlarmDialogProps } from "./interfaces/IDialogProps";
import { useDialogStyles } from "./useDialogStyles";

import alarm from "../../assets/audios/alarm-clock.mp3";

export const AlarmDialog: React.FC<IAlarmDialogProps> = (props) => {
  const { open, content, onClose } = props;
  const classes = useDialogStyles();
  const audioRef = useRef<HTMLAudioElement | null>(null);

  // useEffect(() => {
  //   if (open) {
  //     const audio = new Audio(alarm);
  //     audio.play();
  //   } else {
  //     audioRef.current?.pause();
  //     if (audioRef.current) {
  //       audioRef.current.currentTime = 0;
  //     }
  //   }
  // }, [open]);

  return (
    <>
      <Dialog
        open={open}
        title="Alert"
        onClose={onClose}
        size="sm"
        actions={[
          {
            type: "button",
            variant: "contained",
            label: "Close",
            onClick: onClose,
          },
        ]}
      >
        <audio ref={audioRef} src={alarm} />
        <Box display={"flex"} justifyContent={"center"} sx={{ mb: 2 }}>
          <NotificationsActiveIcon className={classes.alarmIcon} />
        </Box>
        <CustomTypography variant="h6" textAlign={"center"} sx={{ mb: 2 }}>
          {content}
        </CustomTypography>
      </Dialog>
    </>
  );
};
