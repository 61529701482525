import React, { useEffect, useState } from "react";
import CardBackground from "../../assets/images/backgrounds/3.png";
import { Box, Typography } from "@mui/material";
import { IMembershipCardProps } from "./interfaces/IMembershipCardProps";
import { api } from "../../api/api";
import { useAuth } from "../../contexts/AuthProvider/AuthProvider";
import { DownloadPopup } from "../DownloadPop/DownloadPopup";
import { useMessagesPopup } from "../../contexts/MessagesPopupProvider/MessagesPopupProvider";

export const MembershipCard: React.FC<IMembershipCardProps> = (props) => {
  const { cardInfo, download, onDownloadComplete, packageType } = props;
  const [loading, setLoading] = useState(false);
  const [downloadPercentage, setDownloadPercentage] = useState(0);
  const [popupOpen, setPopupOpen] = useState(false);
  const auth = useAuth();
  const { showTopErrorSnackbar, showErrorMessage } = useMessagesPopup();

 React.useEffect(() => {
    const downloadMembershipCard = async () => {
      if (auth.user && auth.user.tokens) {
        try {
          setLoading(true);
          setPopupOpen(true); // Open the popup

          let rsaUrl = cardInfo.membershipCode.startsWith("3388")
            ? `/download-membership-card?membership_code_v=${cardInfo.membershipCode}`
            : `/download-old-membership-card?membership_code_v=${cardInfo.membershipCode}`;

          let urlEndPoint =
            packageType === "SOW"
              ? `/download-sow-membership-card?membership_code_v=${cardInfo.membershipCode}`
              : rsaUrl;

          const res = await api.get(urlEndPoint, {
            responseType: "blob",
            headers: {
              "auth-Token": auth.user.tokens.access,
            },
            onDownloadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              if (total !== 0) {
                const percentage = Math.round((loaded * 100) / total);
                setDownloadPercentage(percentage); // Update percentage
              }
            },
          });

          // Check for "Invalid package details" message in the response
          if (res?.data?.msg === "Invalid package details") {
            showErrorMessage("Invalid package details"); // Show error message
            setLoading(false);
            setPopupOpen(false); // Close the popup
            return; // Stop further processing
          }

          const url = URL.createObjectURL(res.data);
          const link = document.createElement("a");
          link.href = url;
          link.download = `card_${cardInfo.membershipCode}`;
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          onDownloadComplete();
        } catch (err: any) {
          setLoading(false);
          showErrorMessage(err.response?.data?.msg || "Error downloading file");
        } finally {
          setLoading(false);
          setPopupOpen(false); // Close the popup
        }
      }
    };

    if (download) {
      downloadMembershipCard();
    }
  }, [download, cardInfo.membershipCode, onDownloadComplete]);

  return (
    <Box
      id="membershipCard"
      sx={{
        display: "none",
        position: "relative",
        borderRadius: "5px",
        width: { md: "70%", xs: "100%" },
      }}
    >
      <img src={CardBackground} style={{ width: "100%" }} alt="cardImage" />
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          top: { md: "22%", xs: "20%" },
        }}
      >
        <Typography
          variant="h1"
          fontWeight={"bold"}
          textAlign={"center"}
          sx={{ color: "#FFCA59", fontSize: { md: "25px", xs: "10px" } }}
        >
          {cardInfo?.membershipCode
            ? cardInfo.membershipCode.replace(/\d{4}(?=.)/g, "$& ")
            : "N/A"}
        </Typography>

        <Box sx={{ mt: { md: 9, xs: 4 } }}>
          <Typography
            variant="h3"
            fontWeight={"bold"}
            textAlign={"center"}
            sx={{ color: "#fff", fontSize: { md: "35px", xs: "15px" } }}
          >
            {cardInfo.membershipCode.replace(/\d{4}(?=.)/g, "$& ")}
          </Typography>
        </Box>
      </Box>

      {/* Render the download progress popup */}
      <DownloadPopup
        open={popupOpen}
        percentage={downloadPercentage}
        onClose={() => setPopupOpen(false)} // Close popup
      />
 
    </Box>
  );
};
