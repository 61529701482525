import { userRoles } from "../contexts/AuthProvider/AuthProvider";

export const rolesDashboards = {
  [userRoles.ADMIN]: "/admin-main",
  [userRoles.CXO]: "/cxo-main",
  [userRoles.CALL_CENTER_LEAD]: "/ccl-main",
  [userRoles.CONTROL_ROOM_LEAD]: "/crl-main",
  [userRoles.MECHANIC]: "/mechanic-main",
  [userRoles.SALES_TEAM_LEAD]: "/sales-main",
  [userRoles.AGENT]: "/agent-main",
  [userRoles.FIELD_EXECUTIVE_MANAGER]: "/field-manager-main",
  [userRoles.IT]: "/it-main",
  [userRoles.FIELD_EXECUTIVE]: "/dashbaord",
  [userRoles.FINANCE]: "/finance-main",
  [userRoles.SALES_MANAGER]: "/sales-manager-main",
  [userRoles.DSA]: "/dashboard",
  [userRoles.ONLINE_DSA]: "/online-dsa-main",
  [userRoles.CONTROL_ROOM_MANAGER]: "/ccm-main"
};
