import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { io, Socket } from "socket.io-client";
import alarmAudio from "../../assets/audios/alarm-clock.mp3";
import { useAuth } from "../AuthProvider/AuthProvider";
import { useMessagesPopup } from "../MessagesPopupProvider/MessagesPopupProvider";
import { AlarmDialog } from "../../components/Dialogs/AlarmDialog";
import { Box, Typography } from "@mui/material";

interface SocketContextType {
  socket: Socket | null;
}

const SocketContext = createContext<SocketContextType>({ socket: null });

export const useSocket = () => useContext(SocketContext);

export const SocketProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user } = useAuth();
  const { showTopSuccessSnackbar } = useMessagesPopup();
  const socketRef = useRef<Socket | null>(null);
  const [isAlarmDialogOpen, setAlarmDialogOpen] = useState(false);
  const [alarmResponse, setAlarmResponse] = useState<{ data: any } | null>(
    null
  );
  const audioRef = useRef<HTMLAudioElement | null>(null);

  // Preload the audio when the component mounts
  useEffect(() => {
    audioRef.current = new Audio(alarmAudio);
    // audioRef.current.play();
  }, []);

  const backendUrl = process.env.REACT_APP_SERVER_URL;
  const finalUrl = backendUrl?.replace(/\/api$/, "").trim();

  useEffect(() => {
    // Check if the user_id is 177 before initializing the socket connection
    if (user.isLogin && user.user_id === 177 && user.tokens?.access) {
      socketRef.current = io(finalUrl as string, {
        query: { token: user.tokens.access },
      });

      socketRef.current.on("newRsaRecord", async (response) => {
        showTopSuccessSnackbar("A new RSA request detected!");
        setAlarmDialogOpen(true);
        setAlarmResponse(response);

        try {
          if (audioRef.current) {
            await audioRef.current.play();
            audioRef.current.loop = true;
          }
        } catch (err) {
          console.error("Failed to play audio:", err);
        }
      });
    }

    return () => {
      socketRef.current?.off("newRsaRecord");
    };
  }, [user.user_id, user.tokens?.access, showTopSuccessSnackbar]);

  const handleCloseAlarmDialog = () => {
    setAlarmDialogOpen(false);
    if (audioRef.current) {
      audioRef.current.pause(); // Pause the audio when the dialog is closed
      audioRef.current.currentTime = 0; // Reset to the start
    }
  };

  return (
    <SocketContext.Provider value={{ socket: socketRef.current }}>
      {children}
      <AlarmDialog
        open={isAlarmDialogOpen}
        content={
          <Box>
            <Typography sx={{ mb: 2, fontSize: "20px", fontWeight: "bold" }}>
              Contact No : {alarmResponse && alarmResponse.data.contact_number}
            </Typography>
            <Typography sx={{ mb: 2, fontSize: "20px", fontWeight: "bold" }}>
              Vehicle No : {alarmResponse && alarmResponse.data.vehicle_number}
            </Typography>
          </Box>
        }
        onClose={handleCloseAlarmDialog}
      />
    </SocketContext.Provider>
  );
};
