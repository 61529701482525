import { action } from "typesafe-actions";
import { ICallReport, ICallReportValue } from "./CallReport.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";

export const FETCH_CALL_REPORT_DATA_PROGRESS = "FETCH_CALL_REPORT_DETAILPROGRESS";
export const FETCH_CALL_REPORT_DATA_SUCCESS = "FETCH_CALL_REPORT_DataUCCESS";
export const FETCH_CALL_REPORT_DATA_FAILED = "FETCH_CALL_REPORT_DETAILFAILED";

export const fetchCallReportDataProgress = () =>
  action(FETCH_CALL_REPORT_DATA_PROGRESS);

export const fetchCallReportDataSuccess = (
  list: ICallReport[],

) => action(FETCH_CALL_REPORT_DATA_SUCCESS, { list });

export const fetchCallReportDataFailed = () => action(FETCH_CALL_REPORT_DATA_FAILED);


export const fetchCallReportData=(fromDate:string,toDate:string,leadId?:string | number, assignId?:string| number,pageNumber?:number,rowsInPerPage?:number):ThunkAction<void, IStoreState, {}, AnyAction>=>async(dispatch,getState)=>{

    try {
        dispatch(fetchCallReportDataProgress());

        let url ="";
        if (leadId!=="-1") {
url += "&sales_team_lead_id=" + leadId;
        }
        if(assignId!=="-1"){
          url += "&assign_to_id=" + assignId;
        }
      
        let finalUrl =
          `get-call-campaign-report?from_date=${fromDate}&to_date=${toDate}`+ url ;
  
        const res = await api.get(finalUrl);
        const data: ICallReport[] = res.data.report ? res.data.report : [];

      
  
  
        dispatch(fetchCallReportDataSuccess(data));
      } catch (error) {
        dispatch(fetchCallReportDataFailed());
      }



}


export const FETCH_CALL_REPORT_DETAILS_PROGRESS = "FETCH_CALL_REPORT_DETAILS_PROGRESS";
export const FETCH_CALL_REPORT_DETAILS_SUCCESS = "FETCH_CALL_REPORT_DETAILS_SUCCESS";
export const FETCH_CALL_REPORT_DETAILS_FAILED = "FETCH_CALL_REPORT_DETAILS_FAILED";

export const fetchCallReportDetailsProgress = () =>
  action(FETCH_CALL_REPORT_DETAILS_PROGRESS);

export const fetchCallReportDetailsSuccess = (
  list: ICallReportValue[],
totalRecords: number
) => action(FETCH_CALL_REPORT_DETAILS_SUCCESS, { list,totalRecords });

export const fetchCallReportDetailsFailed = () => action(FETCH_CALL_REPORT_DETAILS_FAILED);


export const fetchCallReportDetailsAsync=(fromDate:string,toDate:string,pageNumber:number,rowsInPerPage:number,callDisposition?:string, leadId?:string| number  , assingId?:string | number):ThunkAction<void, IStoreState, {}, AnyAction>=>async(dispatch,getState)=>{

  
  
  

    try {
        dispatch(fetchCallReportDetailsProgress());
        let url = "";
        if (callDisposition) {
          url += "&call_disposition=" + callDisposition;
        }
        if (leadId && !isNaN(Number(leadId))) {
          url += `&sales_team_lead_id=${leadId}`;
        }
        if (assingId && !isNaN(Number(assingId))) {
          url += `&assign_to_id=${assingId}`;
        }
      
        let finalUrl =
          `get-call-campaign-report2?from_date=${fromDate}&to_date=${toDate}&pageNo=${pageNumber}&itemsPerPage=${rowsInPerPage}`+ url ;
  
        const res = await api.get(finalUrl);
        const data: ICallReportValue[] = res.data.result ? res.data.result : [];
const totalRecords = res.data.totalRecords;



      
  
  
        dispatch(fetchCallReportDetailsSuccess(data, totalRecords));
      } catch (error) {
        dispatch(fetchCallReportDetailsFailed());
      }



}