import React from "react";
import { api } from "../../api/api";
import axios from "axios";
import LinearProgress from "@mui/material/LinearProgress";

export enum userRoles {
  ADMIN = "ADMIN",
  CXO = "CXO",
  CALL_CENTER_LEAD = "CALL_CENTER_LEAD",
  CONTROL_ROOM_LEAD = "CONTROL_ROOM_LEAD",
  SALES_TEAM_LEAD = "SALES_TEAM_LEAD",
  MECHANIC = "MECHANIC",
  AGENT = "AGENT",
  FIELD_EXECUTIVE_MANAGER = "FIELD_EXECUTIVE_MANAGER",
  FIELD_EXECUTIVE = "FIELD_EXECUTIVE",
  IT = "IT",
  FINANCE = "FINANCE",
  None = "None",
  SALES_MANAGER = "SALES_MANAGER",
  DSA = "DSA",
  ONLINE_DSA = "ONLINE_DSA",
  CONTROL_ROOM_MANAGER="CONTROL_ROOM_MANAGER",
}

export interface IAuth {
  user: {
    name: string;
    email: string;
    isLogin: boolean;
    user_id: number | string;
    role: keyof typeof userRoles;
    office: string;
    tokens: { access: string; refresh: string } | null;
  };
  login: (
    email: string,
    password: string,
    onSuccess: (role: keyof typeof userRoles) => void,
    onError: (message: string) => void
  ) => void;
  logout: () => void;
  addUserName: (name: string) => void;
}

const AuthContext = React.createContext<IAuth>({
  user: {
    name: "",
    email: "",
    role: userRoles.None,
    isLogin: false,
    tokens: null,
    user_id: "",
    office: "", // check- 1
  },
  login: () => {},
  logout: () => {},
  addUserName: () => {},
});

export const useAuth = () => React.useContext(AuthContext);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  const [user, setUser] = React.useState<IAuth["user"]>({
    name: "",
    email: "",
    role: userRoles.None,
    isLogin: false,
    tokens: null,
    user_id: "",
    office: "", //check- 2
  });
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const auth = localStorage.getItem("auth");
    if (auth) {
      const data = JSON.parse(auth);
      const date1 = new Date(data.date);
      const date2 = new Date();
      const diff = date2.getMonth() - date1.getMonth();
      if (diff <= 1) {
        setUser(data);
      }
    }
    setLoading(false);
  }, []);

  const handleLogin = async (
    email: string,
    password: string,
    onSuccess: (role: keyof typeof userRoles) => void,
    onError: (message: string) => void
  ) => {
    try {
      const res1 = await axios.get("https://ipapi.co/json/");
      const ipAddress = res1.data.ip;

      // const ipAddress = "122.176.157.111";

      const res = await api.post("/login-with-email", {
        email: email,
        password: password,
        ip: ipAddress,
      });
      const user = res.data.user;
      const data = {
        name: user.user_name,
        email: user.email,
        role: userRoles[user.role_name as "None"],
        isLogin: true,
        user_id: user.user_id,
        office: user.office, //check -3
        tokens: res.data.Tokens,
      };
      localStorage.setItem(
        "auth",
        JSON.stringify({ ...data, date: new Date() })
      );
      setUser(data);
      onSuccess(userRoles[user.role_name as "None"]);
    } catch (error: any) {
      onError(error.response.data.msg);
    }
  };

  const handleLogout = () => {
    localStorage.clear();

    // dispatch({ type: USER_LOGOUT });
    // navigate("/auth/login");
    window.open("/auth/login", "_self");
    // setUser({
    //   isLogin: false,
    //   name: "",
    //   email: "",
    //   tokens: null,
    //   role: userRoles["None"],
    // });
  };

  const addUserName = (name: string) => {
    setUser({ ...user, name: name });
  };

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <AuthContext.Provider
      value={{
        user: user,
        login: handleLogin,
        logout: handleLogout,
        addUserName: addUserName,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
