import produce from "immer";
import { GoogleNearByActions, MechanicsActions } from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import {
    FETCH_GOOGLE_NEAR_BY_ERROR,
    FETCH_GOOGLE_NEAR_BY_PROGRESS,
    FETCH_GOOGLE_NEAR_BY_SUCCESS,
    FETCH_MECHANICS_LIST_ERROR, FETCH_MECHANICS_LIST_PROGRESS, FETCH_MECHANICS_LIST_SUCCESS,
    FETCH_SINGLE_MECHANIC_ERROR, FETCH_SINGLE_MECHANIC_PROGRESS, FETCH_SINGLE_MECHANIC_SUCCESS
} from "./mechanics.constants";
import { defaultGoogleNearByState, defaultMechanicState } from "./mechanics.state";

export const mechanicsReducer = (
    state: IStoreState["mechanics"] = defaultMechanicState,
    action: MechanicsActions
) => {
    switch (action.type) {
        case FETCH_MECHANICS_LIST_PROGRESS: {
            const newState = produce(state, (draftState) => {
                draftState.mechanics_list.loading = LoadState.InProgress;
            });
            return newState;
        }
        case FETCH_MECHANICS_LIST_SUCCESS: {
            const newState = produce(state, (draftState) => {
                draftState.mechanics_list.data = action.payload.data;
                draftState.mechanics_list.loading = LoadState.Loaded;
            });
            return newState;
        }
        case FETCH_MECHANICS_LIST_ERROR: {
            const newState = produce(state, (draftState) => {
                draftState.mechanics_list.data = [];
                draftState.mechanics_list.loading = LoadState.NotLoaded;
            });
            return newState;
        }


        case FETCH_SINGLE_MECHANIC_PROGRESS: {
            const newState = produce(state, (draftState) => {
                draftState.single_mechanic.loading = LoadState.InProgress;
            });
            return newState;
        }
        case FETCH_SINGLE_MECHANIC_SUCCESS: {
            const newState = produce(state, (draftState) => {
                draftState.single_mechanic.data = action.payload.data;
                draftState.single_mechanic.loading = LoadState.Loaded;
            });
            return newState;
        }
        case FETCH_SINGLE_MECHANIC_ERROR: {
            const newState = produce(state, (draftState) => {
                draftState.single_mechanic.data = defaultMechanicState["single_mechanic"]["data"];
                draftState.single_mechanic.loading = LoadState.NotLoaded;
            });
            return newState;
        }

       
        default:
            return state;
    }
};

export const googleNearByReducer = (
    state: IStoreState["googleNearBy"] = defaultGoogleNearByState,
    action: GoogleNearByActions
) => {
    switch (action.type) {
        case FETCH_GOOGLE_NEAR_BY_PROGRESS: {
            const newState = produce(state, (draftState) => {
                draftState.location.loading = LoadState.InProgress;
            });
            return newState;
        }
        case FETCH_GOOGLE_NEAR_BY_SUCCESS: {
            const newState = produce(state, (draftState) => {
                draftState.location.data = action.payload.data;
                draftState.location.loading = LoadState.Loaded;
            });
            return newState;
        }
        case FETCH_GOOGLE_NEAR_BY_ERROR: {
            const { errorMessage } = action.payload;
            const newState = produce(state, (draftState) => {
                draftState.location.error=errorMessage
                draftState.location.loading = LoadState.NotLoaded;
            });
            return newState;
        }
        default:
            return state;
}
}