import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../redux/initialStoreState";
import { Alert, IconButton, Snackbar } from "@mui/material";

import { hideMessage } from "../../redux/messages/messagesActions";
import CloseIcon from "@mui/icons-material/Close";
import LoadingDialog from "../Dialogs/LoadingDialog";
import { SuccessDialog } from "../Dialogs/SuccessDialog";
import ActivatingLoadingDialog from "../Dialogs/ActivateLoadingDialog";
import { useDispatchWrapper } from "../../hooks/useDispatch";


export const MessageBox: React.FC = () => {
  const { item, saveLoader, convertLoader } = useSelector(
    (storeState: IStoreState) => storeState.message,
  );

  const dispatch = useDispatchWrapper();

  const handleClearMessage = () => {
    dispatch(hideMessage());
  };

  if (item) {
    if (item.displayAs === "snackbar") {
      return (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={true}
          autoHideDuration={5000}
          onClose={handleClearMessage}
          key={"topright"}
        >
          <Alert
            severity={item.type}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleClearMessage}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {item.message}
          </Alert>
        </Snackbar>
      );
    }
   
  }
  if (saveLoader) {
    return <LoadingDialog open={saveLoader} />;
  }

  if (convertLoader) {
    return <ActivatingLoadingDialog open={convertLoader} />;
  }
  return <></>;
};
