import { IComplaint } from "../../constants/commonTypes";

export interface IUserTransaction {
  user_prospect_id: number;
  olddb_tempid: number | null;
  user_name: string;
  contact_no: string;
  email_id: string;
  state: string;
  membership_code: string;
  city: string;
  area: string;
  address: string;
  gstin: string;
  vehicle_no: string;
  make_name: string;
  model_name: string;
  package_name: string;
  package_id: number;
  package_price: number;
  package_period: number;
  package_services: number;
  comment: string;
  payment_date: string;
  payment_mode: string;
  promo_code: string;
  referral_code: string;
  status: "ACTIVE" | "PENDING";
  hof_id: number | null;
  partner_id: number;
  gst_percentage: number;
  gst_amount: number;
  insert_dt: string;
  branch_office: string;
  vehicle_number: string;
  maker_name: string;
  total_services: number;
  used_services: number;
  contact_number: string;
  remain_services: number;
  membership_start_date: string;
  membership_expiry_date: string;
  new?: boolean;
}

export interface IOnlineDashboard {
  user_prospect_id: number;
  user_prospect_dim_id: number;
  user_name: string;
  contact_no: string;
  email_id: string;
  state: string;
  city: string;
  area: string;
  pin_code: string;
  address: string;
  gstin: string;
  vehicle_no: string;
  make_name: string;
  model_name: string;
  registration_year: string;
  package_name: string;
  package_id: number;
  package_price: number;
  package_period: number;
  package_services: number;
  created_by: string | null;
  sales_lead_id: number | null;
  manager_id: number | null;
  created_from: string;
  created_at: string;
  fe_id: number;
  promo_code: string;
  referral_code: string;
  partner_id: number;
  gst_percentage: number;
  gst_amount: number;
  iteration: number;
  payment_mode: string;
  payment_status: string;
  membership_code: string;
  payment_date: string;
  status: string;
  comment: string;
  remark: string;
  insert_dt: string;
  total_deduction: number;
}

export interface IOnlineDashbaord {
  onlineDsa: {
    transactionsByDate: {
      [key: string]:
        | {
            list: IOnlineDashboard[];
            count: number;
          }
        | undefined;
    };
  };
}

export const initialOnlineDashboard: IOnlineDashbaord = {
  onlineDsa: {
    transactionsByDate: {},
  },
};
