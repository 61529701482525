import produce from "immer";
import { DashboardActions } from ".";
import { FETCH_USER_TRANSACTIONS_BY_DATE } from "../constants";
import { IStoreState } from "../initialStoreState";
import { initialOnlineDashboard } from "./types";

export const onlineDashboardReducer = (
  state: IStoreState["onlineDsa"] = initialOnlineDashboard,
  action: DashboardActions
) => {
  switch (action.type) {
    case FETCH_USER_TRANSACTIONS_BY_DATE: {
      const { key, list, count } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.onlineDsa.transactionsByDate[key] = {
          count: count,
          list: list,
        };
      });
      return newState;
    }

    default:
      return state;
  }
};
