import { IServices } from "../../constants/commonTypes";
import { LoadState } from "../../constants/enums";

export interface IServiceState {
  list: {
    services: IServices[];
    totalRecords: number;
    loadState: LoadState;
    error: string | null;
  };
}

export const initialServicesState: IServiceState = {
  list: {
    services: [],
    totalRecords: 0,
    loadState: LoadState.NotLoaded,
    error: null,
  },
};
