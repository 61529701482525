import React from "react";
import { Dialog } from "../../../Dialogs/Dialog";
import { Grid, Typography } from "@mui/material";
import { StandadCard } from "../../../Cards";
import { StandardCellList } from "../../../Standards";
import { formatDate } from "../../../../helpers";
import { IViewCustomerConnection } from "./IViewCustomerConnection";
import { api } from "../../../../api/api";
import { useAuth } from "../../../../contexts/AuthProvider/AuthProvider";
import { GetVehicle } from "../../../../views/complaint/components/ComplaintLog/AddVehicle/GetVehicle";

interface IVehicleList {
  vehicle_no: string;
  model_name: string;
  maker_name: string;
  status: "ACTIVE" | "PENDING";
  registration_year_v: number;
}

interface IPackageDetail {
  membership_code: string;
  package_name: string;
  duration: number;
  type: string;
  package_price: number;
  payment_date: string;
}

export const ViewCustomerConnection: React.FC<IViewCustomerConnection> = (
  props
) => {
  const { open, connection, onClose } = props;

  const allowedPackageIds = [1, 21, 22, 23];
  const [vehicleList, setVehicleList] = React.useState<IVehicleList[]>([]);
  const [packageDetails, setPackageDetails] =
    React.useState<IPackageDetail | null>(null);
  const auth = useAuth();

  React.useEffect(() => {
    const fetchVehicleList = async () => {
      if (auth.user && auth.user.tokens) {
        try {
          const res = await api.get(
            `/get-family-added-vehicles?membership_code_v=${connection.membership_code}`,
            {
              headers: {
                "auth-Token": auth.user.tokens.access,
              },
            }
          );
          setVehicleList(res.data.result);
        } catch (err: any) {
          console.error(err);
        }
      }
    };

    const fetchPackageDetails = async () => {
      if (auth.user && auth.user.tokens) {
        try {
          const res = await api.get(
            `/check-vehicle-phone-exist-by-membershipcode?membership_code=${connection.membership_code}`,
            {
              headers: {
                "auth-Token": auth.user.tokens.access,
              },
            }
          );
          setPackageDetails(res.data.getResult[0]);
        } catch (err: any) {
          console.error(err);
        }
      }
    };

    if (allowedPackageIds.includes(connection.package_id) && open) {
      fetchVehicleList();
      fetchPackageDetails();
    }
  }, [connection.package_id, connection.membership_code, open]);
  const current_date = new Date();
  const expiryDate = new Date(connection.membership_expiry_date);
  const isActive = expiryDate > current_date;

  return (
    <Dialog
      open={open}
      title="Customer Details"
      onClose={onClose}
      size="md"
      actions={[
        { type: "button", label: "Close", variant: "text", onClick: onClose },
      ]}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} lg={12}>
          <StandadCard heading="About Customer" sx={{ mb: 2 }}>
            <StandardCellList
              colSize={3}
              list={[
                {
                  heading: "User ID",
                  data: {
                    type: "text",
                    value: "# " + connection.user_id,
                  },
                },
                {
                  heading: "Name",
                  data: { type: "text", value: connection.user_name },
                },
                {
                  heading: "Contact Number",
                  data: {
                    type: "text",
                    value: connection.contact_number,
                  },
                },
                {
                  heading: "Email ID",
                  data: {
                    type: "text",
                    value: connection.email_id,
                  },
                },
                {
                  heading: "Date",
                  data: {
                    type: "text",
                    value: formatDate(connection.insert_dt),
                  },
                },
                {
                  heading: "State",
                  data: { type: "text", value: connection.state },
                },
                {
                  heading: "City",
                  data: { type: "text", value: connection.city },
                },
                {
                  heading: "Area",
                  data: { type: "text", value: connection.area },
                },
                {
                  heading: "Address",
                  data: { type: "text", value: connection.address },
                },
                {
                  heading: "Customer Status",
                  data: {
                    type: "text",
                    value: connection.status,
                    color:
                      connection.status === "ACTIVE"
                        ? "success.main"
                        : "error.main",
                  },
                },
              ]}
            />
          </StandadCard>
        </Grid>
        <Grid item xs={12} lg={8}>
          <StandadCard heading="Additional Information" sx={{ mb: 2 }}>
            <StandardCellList
              colSize={4}
              list={[
                // {
                //   heading: "Package Name",
                //   data: {
                //     type: "text",
                //     value: packageDetails?.package_name || "N/A",
                //   },
                // },
                // {
                //   heading: "Paid Amount",
                //   data: {
                //     type: "text",
                //     value: packageDetails?.package_price || "N/A",
                //   },
                // },
                {
                  heading: "Total Services",
                  data: { type: "text", value: connection.total_services },
                },
                {
                  heading: "Used Services",
                  data: {
                    type: "text",
                    value: connection.used_services
                      ? connection.used_services
                      : "0",
                  },
                },

                {
                  heading: "Remaining Services",
                  data: {
                    type: "text",
                    value:
                      connection.remain_services !== 0
                        ? connection.remain_services
                        : "0",
                    color:
                      connection.remain_services !== 0 ? "black" : "error.main",
                  },
                },
                {
                  heading: "Membership Start Date",
                  data: {
                    type: "text",
                    value: formatDate(connection.membership_start_date),
                  },
                },
                {
                  heading: "Membership End Date",
                  data: {
                    type: "text",
                    value: formatDate(connection.membership_expiry_date),
                  },
                },
                {
                  heading: "Membership Status",
                  data: {
                    type: "text",
                    value: isActive ? "ACTIVE" : "INACTIVE",
                    color: isActive ? "success.main" : "error.main",
                  },
                },
                // {
                //   heading: "Remaining Services",
                //   data: { type: "text", value: connection.remain_services },
                // },
                // {
                //   heading: "Duration",
                //   data: {
                //     type: "text",
                //     value:
                //       (packageDetails?.duration
                //         ? packageDetails.duration
                //         : connection.duration) + " Years",
                //   },
                // },
              ]}
            />
          </StandadCard>
        </Grid>
        <Grid item xs={12} lg={4}>
          <StandadCard heading="Primary Vehicle Information" sx={{ mb: 2 }}>
            <StandardCellList
              colSize={6}
              list={[
                {
                  heading: "Vehicle Number",
                  data: {
                    type: "text",
                    value: (
                      <Typography style={{ color: "red" }}>
                        {connection.vehicle_number}
                      </Typography>
                    ),
                  },
                },
                {
                  heading: "Brand",
                  data: { type: "text", value: connection.maker_name },
                },
                {
                  heading: "Model",
                  data: { type: "text", value: connection.model_name },
                },
                {
                  heading: "Membership Code",
                  data: { type: "text", value: connection.membership_code },
                },
              ]}
            />
          </StandadCard>
        </Grid>
        <Grid item xs={12} lg={12}>
          {allowedPackageIds.includes(connection.package_id) && (
            <GetVehicle connection={connection} />
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};
