import React, { lazy } from "react";
import { Navigate, useParams } from "react-router-dom";
import Loadable from "../layout/fullLayout/loadable/loadable";
import { OOPSError } from "../views/authentication/OopsError";
import { SalesDashboard } from "../views/dashboard/SalesDashboard/SalesDashboard";
import { AuthorizedRoute } from "./AuthorizedRoute";
import { ProtectedRoute } from "./ProtectedRoutes";
import { PublicRoute } from "./PublicRoute";
import { RSAList } from "../views/rsaList/RSAList";
import { FEActivationsReport } from "../views/allReports/FEActivationsReport";
import { UpgradePackageForm } from "../views/dashboard/ITDashboard/ImportantSection/upgradePackage/UpgardePackageForm";
import { EditComplaint } from "../views/assignComplaint/components/EditComplaint/EditComplaint";
import { UserTransactions } from "../views/dashboard/CXODashboard/components/UserTransactions/UserTransactions";
import { SowTeamLeadTableReport } from "../views/allReports/salesmanageReport/SowTeamLeadTableReport";

/* ***Layouts*** */

const FullLayout = Loadable(
  lazy(() =>
    import("../layout/fullLayout/FullLayout").then(({ FullLayout }) => ({
      default: FullLayout,
    }))
  )
);
const BlankLayout = Loadable(
  lazy(() => import("../layout/blankLayout/BlankLayout"))
);
/* ***End Layouts**** */

const Error = Loadable(
  lazy(() =>
    import("../views/authentication/Error").then(({ Error }) => ({
      default: Error,
    }))
  )
);

/* ****Pages***** */

const AdminDashboard = Loadable(
  lazy(() =>
    import("../views/dashboard/AdminDashboard/AdminDashboard").then(
      ({ AdminDashboard }) => ({
        default: AdminDashboard,
      })
    )
  )
);

const RegisterUser = Loadable(
  lazy(() =>
    import("../views/registerUser/RegisterUser").then(({ RegisterUser }) => ({
      default: RegisterUser,
    }))
  )
);

const DsaActivation = Loadable(
  lazy(() =>
    import(
      "../views/dashboard/FinanceDashboard/DSAActivation/DsaActivation"
    ).then(({ DsaActivation }) => ({
      default: DsaActivation,
    }))
  )
);
const AssignedCards = Loadable(
  lazy(() =>
    import(
      "../views/memberActivation/components/CompleteDsaActivation/AllAssignedCards"
    ).then(({ AssignedCards }) => ({
      default: AssignedCards,
    }))
  )
);

const RegisteredUsers = Loadable(
  lazy(() =>
    import("../views/registerUser/RegisteredUsers").then(
      ({ RegisteredUsers }) => ({
        default: RegisteredUsers,
      })
    )
  )
);

const MultipleAgents = Loadable(
  lazy(() =>
    import("../views/registerUser/MultipleAgents").then(
      ({ MultipleAgents }) => ({
        default: MultipleAgents,
      })
    )
  )
);

const CXODashboard = Loadable(
  lazy(() =>
    import("../views/dashboard/CXODashboard/CXODashboard").then(
      ({ CXODashboard }) => ({
        default: CXODashboard,
      })
    )
  )
);

const SalesManagerDashboard = Loadable(
  lazy(() =>
    import(
      "../views/dashboard/SalesManagerDasboard/SalesManagerDashboard"
    ).then(({ SalesManagerDashboard }) => ({
      default: SalesManagerDashboard,
    }))
  )
);

const CCLDashbaord = Loadable(
  lazy(() =>
    import("../views/dashboard/CCLDashbaord/CCLDashbaord").then(
      ({ CCLDashbaord }) => ({
        default: CCLDashbaord,
      })
    )
  )
);

const CCLComplaint = Loadable(
  lazy(() =>
    import("../views/complaint/Complaint").then(({ Complaint }) => ({
      default: Complaint,
    }))
  )
);
const SOWCCLComplaint = Loadable(
  lazy(() =>
    import("../views/SowComplaints/SowComplaint").then(({ SowComplaint }) => ({
      default: SowComplaint,
    }))
  )
);

const InvoiceReport = Loadable(
  lazy(() =>
    import("../views/allReports/salesmanageReport/InvoiceReport").then(
      ({ InvoiceReport }) => ({
        default: InvoiceReport,
      })
    )
  )
);

const CRLDashbaord = Loadable(
  lazy(() =>
    import("../views/dashboard/CRLDashboard/CRLDashboard").then(
      ({ CRLDashbaord }) => ({
        default: CRLDashbaord,
      })
    )
  )
);
const FinanceDashboard = Loadable(
  lazy(() =>
    import("../views/dashboard/FinanceDashboard/FinanceDashboard").then(
      ({ FinanceDashboard }) => ({
        default: FinanceDashboard,
      })
    )
  )
);

const ReferralReports = Loadable(
  lazy(() =>
    import("../components/Referrals/ReferralReports/ReferralReports").then(
      ({ ReferralReports }) => ({
        default: ReferralReports,
      })
    )
  )
);

// const LatestReferrals = Loadable(
//   lazy(() =>
//     import("../components/Referrals/LatestReferrals/LatestReferrals").then(
//       ({ LatestReferrals }) => ({
//         default: LatestReferrals,
//       })
//     )
//   )
// );

const ManageUserReferral = Loadable(
  lazy(() =>
    import("../views/manageUserReferral/LatestReferrals/LatestReferrals").then(
      ({ ManageUserReferral }) => ({
        default: ManageUserReferral,
      })
    )
  )
);

const AssignComplaint = Loadable(
  lazy(() =>
    import("../views/assignComplaint/AssignComplaint").then(
      ({ AssignComplaint }) => ({
        default: AssignComplaint,
      })
    )
  )
);

const SowAssignService = Loadable(
  lazy(() =>
    import("../views/assignServices/AssignServices").then(
      ({ AssignServices }) => ({
        default: AssignServices,
      })
    )
  )
);

const ComplaintsReport = Loadable(
  lazy(() =>
    import("../views/allReports/salesmanageReport/ComplaintsReport").then(
      ({ ComplaintsReport }) => ({
        default: ComplaintsReport,
      })
    )
  )
);
const ServicesReport = Loadable(
  lazy(() =>
    import("../views/allReports/salesmanageReport/ServiceReport").then(
      ({ ServicesReport }) => ({
        default: ServicesReport,
      })
    )
  )
);

const ComplaintLogTableReport = Loadable(
  lazy(() =>
    import(
      "../views/allReports/salesmanageReport/ComplaintLogTableReport"
    ).then(({ ComplaintLogTableReport }) => ({
      default: ComplaintLogTableReport,
    }))
  )
);

const PaymentClearenceReport = Loadable(
  lazy(() =>
    import("../views/allReports/PaymentClearenceReport").then(
      ({ PaymentClearenceReport }) => ({
        default: PaymentClearenceReport,
      })
    )
  )
);

const MechanicDashboard = Loadable(
  lazy(() =>
    import("../views/dashboard/MechanicDashboard/MechanicDashboard").then(
      ({ MechanicDashboard }) => ({
        default: MechanicDashboard,
      })
    )
  )
);

const SalesDashbaord = Loadable(
  lazy(() =>
    import("../views/dashboard/SalesDashboard/SalesDashboard").then(
      ({ SalesDashboard }) => ({
        default: SalesDashboard,
      })
    )
  )
);
const OnlineDsaDashbaord = Loadable(
  lazy(() =>
    import("../views/dashboard/OnlineDsaDashboard/OnlineDSADashboard").then(
      ({ OnlineDSADashboard }) => ({
        default: OnlineDSADashboard,
      })
    )
  )
);
const OnlineDsaRsaDashbaord = Loadable(
  lazy(() =>
    import("../views/onlineDsaRecord/onlineRsaRecord/OnlineRsaRecord").then(
      ({ OnlineRsaRecord }) => ({
        default: OnlineRsaRecord,
      })
    )
  )
);
const OnlineDsaSowDashbaord = Loadable(
  lazy(() =>
    import("../views/onlineDsaRecord/onlineSowRecord/OnlineSowRecords").then(
      ({ OnlineSowRecord }) => ({
        default: OnlineSowRecord,
      })
    )
  )
);
const OnlineDsaPromoCode = Loadable(
  lazy(() =>
    import("../views/OnlineDsaPromoCode/OnlinePromoCode").then(
      ({ OnlineDsaPromoCode }) => ({
        default: OnlineDsaPromoCode,
      })
    )
  )
);
const PendingPayemntCollection = Loadable(
  lazy(() =>
    import(
      "../views/dashboard/FinanceDashboard/FinanceComponent/PendingPaymentCollection/PendingPaymentCollection"
    ).then(({ PendingPaymentCollection }) => ({
      default: PendingPaymentCollection,
    }))
  )
);

const PhysicalCardGeneration = Loadable(
  lazy(() =>
    import("../views/physicalCardgeneration/PhysicalCardgeneration").then(
      ({ PhysicalCardGeneration }) => ({
        default: PhysicalCardGeneration,
      })
    )
  )
);

const DsaReport = Loadable(
  lazy(() =>
    import("../views/DsaReport/DsaReport").then(({ DsaReport }) => ({
      default: DsaReport,
    }))
  )
);

const AssignDsaCards = Loadable(
  lazy(() =>
    import(
      "../views/dashboard/FinanceDashboard/FinanceComponent/CardAssign/AssignDsaCards"
    ).then(({ AssignDsaCards }) => ({
      default: AssignDsaCards,
    }))
  )
);

const UpgradePackage = Loadable(
  lazy(() =>
    import(
      "../views/dashboard/ITDashboard/ImportantSection/upgradePackage/UpgradePackage"
    ).then(({ UpgradePackage }) => ({
      default: UpgradePackage,
    }))
  )
);
const GenerateProductLink = Loadable(
  lazy(() =>
    import("../views/generateProductLink/GenerateProductLink").then(
      ({ GenerateProductLink }) => ({
        default: GenerateProductLink,
      })
    )
  )
);
const SowGenerateProductLink = Loadable(
  lazy(() =>
    import("../views/generateSowProductLink/sowGenerateProductLink").then(
      ({ SowGenerateProductLink }) => ({
        default: SowGenerateProductLink,
      })
    )
  )
);
const TodayCallCampaign = Loadable(
  lazy(() =>
    import("../views/CallCampaign/TodayCallCampaign").then(
      ({ TodayCallCampaign }) => ({
        default: TodayCallCampaign,
      })
    )
  )
);
const AllCallCampaign = Loadable(
  lazy(() =>
    import("../views/CallCampaign/AllCallCampaign").then(
      ({ AllCallCampaign }) => ({
        default: AllCallCampaign,
      })
    )
  )
);
const CallCampaignReport = Loadable(
  lazy(() =>
    import("../views/CallCampaign/CallReport2").then(
      ({ CallReportsData }) => ({
        default: CallReportsData,
      })
    )
  )
);

const CallCampaignReportDetails = Loadable(
  lazy(() =>
    import("../views/CallCampaign/CallReportDetails").then(
      ({ CallReportDetails }) => ({
        default: CallReportDetails,
      })
    )
  )
);

const CustomerCampaignDetial = Loadable(
  lazy(() =>
    import(
      "../views/CallCampaign/component/CustomerCampaignDetails/CustomerCaampaignDetails"
    ).then(({ CustomerCaampaignDetails }) => ({
      default: CustomerCaampaignDetails,
    }))
  )
);

const MyReferrals = Loadable(
  lazy(() =>
    import("../views/myReferrals/MyReferrals").then(({ MyReferrals }) => ({
      default: MyReferrals,
    }))
  )
);

const QuoteList = Loadable(
  lazy(() =>
    import("../views/QuoteList/QouteList").then(({ QuoteList }) => ({
      default: QuoteList,
    }))
  )
);

const CarCampaignList = Loadable(
  lazy(() =>
    import("../views/sellCampaign/SelCampaignList").then(
      ({ SellCampaignList }) => ({
        default: SellCampaignList,
      })
    )
  )
);
const NoReferrel = Loadable(
  lazy(() =>
    import("../views/noReferrel/noReferrel").then(({ NoReferrel }) => ({
      default: NoReferrel,
    }))
  )
);

const ProspectBySales = Loadable(
  lazy(() =>
    import("../views/prospectBySales/ProspectBySales").then(
      ({ ProspectBySales }) => ({
        default: ProspectBySales,
      })
    )
  )
);
const SowProspectBySales = Loadable(
  lazy(() =>
    import("../views/sowPropectBySales/SowProspectBySales").then(
      ({ SowProspectBySales }) => ({
        default: SowProspectBySales,
      })
    )
  )
);

const UserForms = Loadable(
  lazy(() =>
    import("../views/userForms/UserForms").then(({ UserForms }) => ({
      default: UserForms,
    }))
  )
);

const AgentDashboard = Loadable(
  lazy(() =>
    import("../views/dashboard/AgentDashbaord/AgentDashbaord").then(
      ({ AgentDashboard }) => ({
        default: AgentDashboard,
      })
    )
  )
);

const LinksByAgents = Loadable(
  lazy(() =>
    import("../views/allReports/salesmanageReport/LinksByAgents").then(
      ({ LinksByAgents }) => ({
        default: LinksByAgents,
      })
    )
  )
);

const ExpiredMembership = Loadable(
  lazy(() =>
    import("../views/ExpiredReport/ExpireReport").then(({ ExpiryReport }) => ({
      default: ExpiryReport,
    }))
  )
);

const FcmInfo = Loadable(
  lazy(() =>
    import("../views/pushNotification/fcmInfo/fcmInfo").then(
      ({ FcmInfoList }) => ({
        default: FcmInfoList,
      })
    )
  )
);

const SendNotification = Loadable(
  lazy(() =>
    import("../views/pushNotification/SendNotification/SendNotification").then(
      ({ SendNotification }) => ({
        default: SendNotification,
      })
    )
  )
);

const NotificationInfo = Loadable(
  lazy(() =>
    import("../views/pushNotification/notificationInfo/notificationInfo").then(
      ({ NotificationInfoList }) => ({
        default: NotificationInfoList,
      })
    )
  )
);
const FieldManagerDashboard = Loadable(
  lazy(() =>
    import(
      "../views/dashboard/FieldManagerDashboard/FieldManagerDashboard"
    ).then(({ FieldManagerDashboard }) => ({
      default: FieldManagerDashboard,
    }))
  )
);

const MemberActivation = Loadable(
  lazy(() =>
    import("../views/memberActivation/MemberActivation").then(
      ({ MemberActivation }) => ({
        default: MemberActivation,
      })
    )
  )
);

const ListMannualActivations = Loadable(
  lazy(() =>
    import("../views/memberActivation/ListMannualActivations").then(
      ({ ListMannualActivations }) => ({
        default: ListMannualActivations,
      })
    )
  )
);

const ListPendingActivations = Loadable(
  lazy(() =>
    import("../views/memberActivation/ListPendingActivations").then(
      ({ ListPendingActivations }) => ({
        default: ListPendingActivations,
      })
    )
  )
);

const CustomerConnection = Loadable(
  lazy(() =>
    import("../views/customerConnection/customerConnection").then(
      ({ CustomerConnection }) => ({
        default: CustomerConnection,
      })
    )
  )
);

const SowCustomerConnection = Loadable(
  lazy(() =>
    import("../views/SowCustomerConnection/sowCustomerConnection").then(
      ({ SowCustomerConnection }) => ({
        default: SowCustomerConnection,
      })
    )
  )
);
const ITDashboard = Loadable(
  lazy(() =>
    import("../views/dashboard/ITDashboard/ITDashboard").then(
      ({ ITDashboard }) => ({
        default: ITDashboard,
      })
    )
  )
);

// products route

const ProductList = Loadable(
  lazy(() =>
    import("../views/Products/ProductList").then(({ ProductList }) => ({
      default: ProductList,
    }))
  )
);

const ManageProducts = Loadable(
  lazy(() =>
    import("../views/Products/ManageProduct").then(({ ManageProduct }) => ({
      default: ManageProduct,
    }))
  )
);

const ActiveMembers = Loadable(
  lazy(() =>
    import("../views/activeMembers/ActiveMembers").then(
      ({ ActiveMembers }) => ({
        default: ActiveMembers,
      })
    )
  )
);
const PromoCode = Loadable(
  lazy(() =>
    import("../views/PromoCode/PromoCode").then(({ PromoCode }) => ({
      default: PromoCode,
    }))
  )
);

const ManageMembership = Loadable(
  lazy(() =>
    import("../views/manageMembership/manageMembership").then(
      ({ ManageMembership }) => ({
        default: ManageMembership,
      })
    )
  )
);

const PromoDetails = Loadable(
  lazy(() =>
    import("../views/PromoDetails/PromoCode").then(({ PromoCode }) => ({
      default: PromoCode,
    }))
  )
);

//promocode info start

const PromoCodeInfo = Loadable(
  lazy(() =>
    import("../views/PormoCodeInfo/PromoCodeInfo").then(
      ({ PromoCodeInfo }) => ({ default: PromoCodeInfo })
    )
  )
);
//promocode info end

const PromoCodeDetails = Loadable(
  lazy(() =>
    import("../views/PromoDetails/PromoCodeDeatails").then(
      ({ PromoCodeDetails }) => ({
        default: PromoCodeDetails,
      })
    )
  )
);
const ManageSowMembership = Loadable(
  lazy(() =>
    import("../views/manageSowMembership/manageSowMembership").then(
      ({ ManageSowMembership }) => ({
        default: ManageSowMembership,
      })
    )
  )
);

const ActivateRsaMembership = Loadable(
  lazy(() =>
    import("../views/activateRsaMembership/activateRsaMembership").then(
      ({ ActivateRSAMembership }) => ({
        default: ActivateRSAMembership,
      })
    )
  )
);
const ActivateSowMembership = Loadable(
  lazy(() =>
    import("../views/activateSowMembership/activateSowMembership").then(
      ({ ActivateSowMembership }) => ({
        default: ActivateSowMembership,
      })
    )
  )
);

const Dashboard = Loadable(
  lazy(() =>
    import("../views/dashboard/Dashboard/Dashbaord").then(({ Dashboard }) => ({
      default: Dashboard,
    }))
  )
);

const ActivationOrders = Loadable(
  lazy(() =>
    import("../views/activationOrders/ActivationOrders").then(
      ({ ActivationOrders }) => ({
        default: ActivationOrders,
      })
    )
  )
);

const CompletedActivationOrders = Loadable(
  lazy(() =>
    import("../views/activationOrders/CompletedActivationOrders").then(
      ({ CompletedActivationOrders }) => ({
        default: CompletedActivationOrders,
      })
    )
  )
);

const SubmissionSummary = Loadable(
  lazy(() =>
    import("../views/submissionSummary/SubmissionSummary").then(
      ({ SubmissionSummary }) => ({
        default: SubmissionSummary,
      })
    )
  )
);

const UserTimeline = Loadable(
  lazy(() =>
    import("../views/UserTimeline/UserTimeline").then(({ UserTimeline }) => ({
      default: UserTimeline,
    }))
  )
);

const CashClearenceReport = Loadable(
  lazy(() =>
    import("../views/allReports/CashClearenceReport").then(
      ({ CashClearenceReport }) => ({
        default: CashClearenceReport,
      })
    )
  )
);

const Login = Loadable(
  lazy(() =>
    import("../views/authentication/Login").then(({ Login }) => ({
      default: Login,
    }))
  )
);
const AccessDenied = Loadable(
  lazy(() =>
    import("../views/authentication/AccessDenied").then(({ AccessDenied }) => ({
      default: AccessDenied,
    }))
  )
);

const Reports = Loadable(
  lazy(() =>
    import("../views/ReportView/Reports").then(({ Reports }) => ({
      default: Reports,
    }))
  )
);
const SOWReports = Loadable(
  lazy(() =>
    import("../views/ReportView/SowReports").then(({ SowReports }) => ({
      default: SowReports,
    }))
  )
);
// const RsaReport = Loadable(
//   lazy(() =>
//     import("../views/rsaReport/RsaReport").then(({ RSAPlanReport }) => ({
//       default: RSAPlanReport,
//     }))
//   )
// );

const SalesManagerReport = Loadable(
  lazy(() =>
    import("../views/allReports/salesmanageReport/salesManagerReport").then(
      ({ SalesManagerReport }) => ({
        default: SalesManagerReport,
      })
    )
  )
);

const SalesManagerTableReport = Loadable(
  lazy(() =>
    import(
      "../views/allReports/salesmanageReport/salesManagerTableReport"
    ).then(({ SalesManagerTableReport }) => ({
      default: SalesManagerTableReport,
    }))
  )
);

const PaymentModeTableReport = Loadable(
  lazy(() =>
    import("../views/allReports/salesmanageReport/PaymentModeTableReport").then(
      ({ PaymentModeTableReport }) => ({
        default: PaymentModeTableReport,
      })
    )
  )
);

const PackageTableReport = Loadable(
  lazy(() =>
    import("../views/allReports/salesmanageReport/PackageTableReport").then(
      ({ PackageTableReport }) => ({
        default: PackageTableReport,
      })
    )
  )
);
const TeamLeadTableReport = Loadable(
  lazy(() =>
    import("../views/allReports/salesmanageReport/TeamLeadTableReport").then(
      ({ TeamLeadTableReport }) => ({
        default: TeamLeadTableReport,
      })
    )
  )
);
const SOWTeamLeadTableReport = Loadable(
  lazy(() =>
    import("../views/allReports/salesmanageReport/SowTeamLeadTableReport").then(
      ({ SowTeamLeadTableReport }) => ({
        default: SowTeamLeadTableReport,
      })
    )
  )
);

const ActivationTeamlead = Loadable(
  lazy(() =>
    import("../views/allReports/salesmanageReport/ActivationTeamlead").then(
      ({ ActivationTeamlead }) => ({
        default: ActivationTeamlead,
      })
    )
  )
);
const ActivationByPackage = Loadable(
  lazy(() =>
    import("../views/allReports/salesmanageReport/ActivationByPackage").then(
      ({ ActivationByPackage }) => ({
        default: ActivationByPackage,
      })
    )
  )
);
const ActivationByPMode = Loadable(
  lazy(() =>
    import("../views/allReports/salesmanageReport/ActivationByPMode").then(
      ({ ActivationByPMode }) => ({
        default: ActivationByPMode,
      })
    )
  )
);

const ChangePassword = Loadable(
  lazy(() =>
    import("../views/ChangePassword/ChangePassword").then(
      ({ ChangePassword }) => ({
        default: ChangePassword,
      })
    )
  )
);

const AddDSAChannel = Loadable(
  lazy(() =>
    import(
      "../views/dashboard/FinanceDashboard/FinanceComponent/AddDsaChannel/AddDsaChannel"
    ).then(({ AddDSAChannel }) => ({
      default: AddDSAChannel,
    }))
  )
);

const EditDSAChannel = Loadable(
  lazy(() =>
    import(
      "../views/dashboard/FinanceDashboard/FinanceComponent/EditDsaChannel/EditDSAChannel"
    ).then(({ EditDSAChannel }) => ({
      default: EditDSAChannel,
    }))
  )
);

const Sales = Loadable(
  lazy(() =>
    import("../views/Analytical/Sales").then(({ Sales }) => ({
      default: Sales,
    }))
  )
);

const TLSales = Loadable(
  lazy(() =>
    import("../views/Analytical/TLSales").then(({ TLSales }) => ({
      default: TLSales,
    }))
  )
);
const PackageSales = Loadable(
  lazy(() =>
    import("../views/Analytical/PackageSales").then(({ PackageSales }) => ({
      default: PackageSales,
    }))
  )
);
const SalesMode = Loadable(
  lazy(() =>
    import("../views/Analytical/SalesMode").then(({ SalesMode }) => ({
      default: SalesMode,
    }))
  )
);

const ManagersSales = Loadable(
  lazy(() =>
    import("../views/Analytical/ManagersSales").then(({ ManagersSales }) => ({
      default: ManagersSales,
    }))
  )
);
const QuickRsa = Loadable(
  lazy(() =>
    import("../views/Analytical/QickRsa").then(({ QuickRsa }) => ({
      default: QuickRsa,
    }))
  )
);

const InvoiceData = Loadable(
  lazy(() =>
    import("../views/invoiceData/InvoiceData").then(({ InvoiceData }) => ({
      default: InvoiceData,
    }))
  )
);

const ImportanctSectionIT = Loadable(
  lazy(() =>
    import(
      "../views/dashboard/ITDashboard/ImportantSection/ImportantSection"
    ).then(({ ImportanctSectionIT }) => ({
      default: ImportanctSectionIT,
    }))
  )
);

const LinkCreatedByAgent = Loadable(
  lazy(() =>
    import("../views/linksCreatedByAgent/LinkCreatedByAgent").then(
      ({ LinkCreatedByAgent }) => ({
        default: LinkCreatedByAgent,
      })
    )
  )
);

const SowLinkCreatedByAgent = Loadable(
  lazy(() =>
    import("../views/sowLinkCreatedByAgent/SowLinkCreatedByAgent").then(
      ({ SowLinkCreatedByAgent }) => ({
        default: SowLinkCreatedByAgent,
      })
    )
  )
);

const InvoiceComposer = Loadable(
  lazy(() =>
    import("../views/invoiceData/InvoiceComposer/InvoiceComposer").then(
      ({ InvoiceComposer }) => ({
        default: InvoiceComposer,
      })
    )
  )
);

const InvoicePayments = Loadable(
  lazy(() =>
    import("../views/InvoicePayments/InvoicePayments").then(
      ({ InvoicePayments }) => ({
        default: InvoicePayments,
      })
    )
  )
);

const LeadsAssignRSAhelp = Loadable(
  lazy(() =>
    import("../views/leadsAssignRSAhelp/leadsAssignRSAhelp").then(
      ({ LeadsAssignRSAhelp }) => ({
        default: LeadsAssignRSAhelp,
      })
    )
  )
);

const AddNewMechanic = Loadable(
  lazy(() =>
    import("../views/AddNewMechanic/AddNewMechanic").then(
      ({ AddNewMechanic }) => ({
        default: AddNewMechanic,
      })
    )
  )
);

const DsaMemberList = Loadable(
  lazy(() =>
    import(
      "../views/dashboard/FinanceDashboard/FinanceComponent/DsaMemberList/DsaMemberList"
    ).then(({ DsaMemberList }) => ({
      default: DsaMemberList,
    }))
  )
);

const EditMechanicDetails = Loadable(
  lazy(() =>
    import("../views/AddNewMechanic/EditMechanicDetails").then(
      ({ EditMechanicDetails }) => ({
        default: EditMechanicDetails,
      })
    )
  )
);
/* ****Routes***** */

export const Router = [
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <FullLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "admin-main",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["ADMIN"]}>
            <AdminDashboard />
          </AuthorizedRoute>
        ),
      },
      {
        path: "register-user",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["ADMIN"]}>
            <RegisterUser />
          </AuthorizedRoute>
        ),
      },

      {
        path: "registered-users",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["ADMIN"]}>
            <RegisteredUsers />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/create-new-mechanic",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["CONTROL_ROOM_LEAD", "CONTROL_ROOM_MANAGER", "IT"]}>
            <AddNewMechanic />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/edit-complaint",
        exact: true,
        element: (
          <AuthorizedRoute
            allowedRoles={["CONTROL_ROOM_LEAD", "CALL_CENTER_LEAD", "CONTROL_ROOM_MANAGER"]}
          >
            <EditComplaint />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/campaign-leads",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["SALES_MANAGER", "CXO"]}>
            <QuoteList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/sell-campaign-leads",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["SALES_MANAGER", "CXO"]}>
            <CarCampaignList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "register-bulk-agents",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["ADMIN"]}>
            <MultipleAgents />
          </AuthorizedRoute>
        ),
      },
      {
        path: "cxo-main",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["CXO"]}>
            <CXODashboard />
          </AuthorizedRoute>
        ),
      },
      {
        path: "Sales-Manager-main",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["SALES_MANAGER"]}>
            <SalesManagerDashboard />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/finance-main",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["FINANCE"]}>
            <FinanceDashboard />
          </AuthorizedRoute>
        ),
      },
      {
        path: "rsa-users-list",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["CXO"]}>
            <RSAList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "user-timeline/:prospectId",
        exact: true,
        element: (
          <AuthorizedRoute
            allowedRoles={[
              "CXO",
              "SALES_TEAM_LEAD",
              "AGENT",
              "FIELD_EXECUTIVE_MANAGER",
            ]}
          >
            <UserTimeline />
          </AuthorizedRoute>
        ),
      },
      {
        path: "ccl-main",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["CALL_CENTER_LEAD"]}>
            <CCLDashbaord />
          </AuthorizedRoute>
        ),
      },

      {
        path: "ccl-complaint",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["CALL_CENTER_LEAD"]}>
            <CCLComplaint />
          </AuthorizedRoute>
        ),
      },
      {
        path: "sow-ccl-complaint",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["CALL_CENTER_LEAD"]}>
            <SOWCCLComplaint />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/package-upgrade",
        children: [
          {
            path: "/package-upgrade/",
            exact: true,
            element: (
              <AuthorizedRoute allowedRoles={["SALES_TEAM_LEAD"]}>
                <UpgradePackage />
              </AuthorizedRoute>
            ),
          },
        ],
      },
      {
        path: "/package-upgrade-form/:id",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["SALES_TEAM_LEAD"]}>
            <UpgradePackageForm />
          </AuthorizedRoute>
        ),
      },

      {
        path: "crl-main",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["CONTROL_ROOM_LEAD"]}>
            <CRLDashbaord />
          </AuthorizedRoute>
        ),
      },
      
      {
        path: "ccm-main",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={[ "CONTROL_ROOM_MANAGER"]}>
            <CRLDashbaord />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/user-transaction-main-report",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["CXO", "FINANCE"]}>
            <UserTransactions />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/crl-assign",
        exact: true,
        element: (
          <AuthorizedRoute
            allowedRoles={["CONTROL_ROOM_LEAD", "CALL_CENTER_LEAD", "CONTROL_ROOM_MANAGER"]}
          >
            <AssignComplaint />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/sow-service-assign",
        exact: true,
        element: (
          <AuthorizedRoute
            allowedRoles={["CONTROL_ROOM_LEAD", "CALL_CENTER_LEAD", "CONTROL_ROOM_MANAGER"]}
          >
            <SowAssignService />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/mechanic-main",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["MECHANIC"]}>
            <MechanicDashboard />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/sales-main",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["SALES_TEAM_LEAD"]}>
            <SalesDashbaord />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/online-dsa-main",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["ONLINE_DSA"]}>
            <OnlineDsaDashbaord />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/online-rsa-details",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["ONLINE_DSA"]}>
            <OnlineDsaRsaDashbaord />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/onlinedsa-promocode",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["ONLINE_DSA"]}>
            <OnlineDsaPromoCode />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/online-sow-details",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["ONLINE_DSA"]}>
            <OnlineDsaSowDashbaord />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/product-link",
        exact: true,
        element: (
          <AuthorizedRoute
            allowedRoles={["SALES_TEAM_LEAD", "AGENT", "ONLINE_DSA", "DSA"]}
          >
            <GenerateProductLink />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/sow-product-link",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["SALES_TEAM_LEAD", "AGENT", "ONLINE_DSA"]}>
            <SowGenerateProductLink />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/call-campaign",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["SALES_TEAM_LEAD", "AGENT"]}>
            <TodayCallCampaign />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/all-call-campaign",
        exact: true,
        element: (
          <AuthorizedRoute
            allowedRoles={["SALES_TEAM_LEAD", "SALES_MANAGER", "CXO"]}
          >
            <AllCallCampaign />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/call-campaign-report",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["SALES_MANAGER", "SALES_TEAM_LEAD"]}>
            <CallCampaignReport />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/call-campaign-report-details/:id",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["SALES_MANAGER", "SALES_TEAM_LEAD"]}>
            <CallCampaignReportDetails />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/custom-campaign-details",
        exact: true,
        element: (
          <AuthorizedRoute
            allowedRoles={["SALES_TEAM_LEAD", "AGENT", "SALES_MANAGER", "CXO"]}
          >
            <CustomerCampaignDetial />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/my-referrals",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["SALES_TEAM_LEAD", "AGENT"]}>
            <MyReferrals />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/no-referrals",
        exact: true,
        element: (
          <AuthorizedRoute
            allowedRoles={["SALES_TEAM_LEAD", "AGENT", "SALES_MANAGER"]}
          >
            <NoReferrel />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/customer-connection",
        exact: true,
        element: (
          <AuthorizedRoute
            allowedRoles={[
              "SALES_TEAM_LEAD",
              "AGENT",
              "CALL_CENTER_LEAD",
              "CONTROL_ROOM_LEAD",
              "CXO",
              "CONTROL_ROOM_MANAGER",
              "SALES_MANAGER",
              "FIELD_EXECUTIVE_MANAGER",
              "DSA",
              "ONLINE_DSA"
            ]}
          >
            <CustomerConnection />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/sow-customer-connection",
        exact: true,
        element: (
          <AuthorizedRoute
            allowedRoles={[
              "SALES_TEAM_LEAD",
              "AGENT",
              "CALL_CENTER_LEAD",
              "CONTROL_ROOM_LEAD",
              "CONTROL_ROOM_MANAGER",
              "CXO",
              "SALES_MANAGER",
              "FIELD_EXECUTIVE_MANAGER",
              "DSA",
            ]}
          >
            <SowCustomerConnection />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/prospect-by-sales",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["SALES_TEAM_LEAD"]}>
            <ProspectBySales />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/sow-prospect-by-sales",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["SALES_TEAM_LEAD"]}>
            <SowProspectBySales />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/user-forms",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["SALES_TEAM_LEAD"]}>
            <UserForms />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/agent-main",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["AGENT"]}>
            <AgentDashboard />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/links-by-agents",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["AGENT", "CXO", "SALES_MANAGER"]}>
            <LinksByAgents />
          </AuthorizedRoute>
        ),
      },

      // *******************push notification *******************
      {
        path: "/fcm-info",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["IT"]}>
            <FcmInfo />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/notification-info",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["IT"]}>
            <NotificationInfo />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/send-notification",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["IT"]}>
            <SendNotification />
          </AuthorizedRoute>
        ),
      },
      // ************ push notification end ************
      {
        path: "/field-manager-main",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["FIELD_EXECUTIVE_MANAGER"]}>
            <FieldManagerDashboard />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/links-created-by-agent",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["AGENT"]}>
            <LinkCreatedByAgent />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/sow-links-created-by-agent",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["AGENT"]}>
            <SowLinkCreatedByAgent />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/manually-activated-user",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["FIELD_EXECUTIVE_MANAGER"]}>
            <ListMannualActivations />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/activation-requests",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["FIELD_EXECUTIVE_MANAGER"]}>
            <ListPendingActivations />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/it-main",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["IT"]}>
            <ITDashboard />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/active-members-list",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["IT"]}>
            <ActiveMembers />
          </AuthorizedRoute>
        ),
      },

      //product route start

      {
        path: "/products",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["IT"]}>
            <ProductList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/products/manage",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["IT"]}>
            <ManageProducts />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/products/manage/:id",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["IT"]}>
            <ProductList />
          </AuthorizedRoute>
        ),
      },

      // product route end

      //promocode info start
      {
        path: "/promocode-info",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["IT", "SALES_MANAGER", "CXO"]}>
            <PromoCodeInfo />
          </AuthorizedRoute>
        ),
      },

      //promocode info end

      {
        path: "/expired-membership-details",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["SALES_MANAGER"]}>
            <ExpiredMembership />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/promo-codes",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["IT"]}>
            <PromoCode />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/manage-membership",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["IT"]}>
            <ManageMembership />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/activate-rsa-membership",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["IT"]}>
            <ActivateRsaMembership />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/activate-sow-membership",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["IT"]}>
            <ActivateSowMembership />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/manage-sow-membership",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["IT"]}>
            <ManageSowMembership />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/dashboard",
        exact: true,
        element: (
          <AuthorizedRoute
            allowedRoles={[
              "FIELD_EXECUTIVE",
              "FINANCE",
              "SALES_MANAGER",
              "DSA",
            ]}
          >
            <Dashboard />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/activation-orders",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["FIELD_EXECUTIVE"]}>
            <ActivationOrders />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/completed-orders-list",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["FIELD_EXECUTIVE"]}>
            <CompletedActivationOrders />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/submission-summary",
        exact: true,
        element: (
          <AuthorizedRoute
            allowedRoles={["FIELD_EXECUTIVE", "FIELD_EXECUTIVE_MANAGER"]}
          >
            <SubmissionSummary />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/sales-by-teamlead",
        exact: true,
        element: (
          <AuthorizedRoute
            allowedRoles={["CXO", "SALES_TEAM_LEAD", "SALES_MANAGER"]}
          >
            <Reports />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/sow-sales-by-teamlead",
        exact: true,
        element: (
          <AuthorizedRoute
            allowedRoles={["CXO", "SALES_TEAM_LEAD", "SALES_MANAGER"]}
          >
            <SOWReports />
          </AuthorizedRoute>
        ),
      },
      {
        path: `/sales-by-teamlead/:managerId`,
        exact: true,
        element: (
          <AuthorizedRoute
            allowedRoles={["CXO", "SALES_TEAM_LEAD", "SALES_MANAGER"]}
          >
            <Reports />
          </AuthorizedRoute>
        ),
      },
      {
        path: `/sow-sales-by-teamlead/:managerId`,
        exact: true,
        element: (
          <AuthorizedRoute
            allowedRoles={["CXO", "SALES_TEAM_LEAD", "SALES_MANAGER"]}
          >
            <SOWReports />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/sales-by-manager",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["CXO", "SALES_TEAM_LEAD"]}>
            <SalesManagerReport />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/sales-by-manager-table-report",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["CXO", "SALES_MANAGER"]}>
            <SalesManagerTableReport />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/sales-by-teamlead-table-report",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["CXO", "SALES_MANAGER"]}>
            <TeamLeadTableReport />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/sow-sales-by-teamlead-table-report",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["CXO", "SALES_MANAGER"]}>
            <SowTeamLeadTableReport />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/sales-by-paymentMode-table-report",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["CXO", "SALES_MANAGER"]}>
            <PaymentModeTableReport />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/sales-by-packageName-table-report",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["CXO", "SALES_MANAGER"]}>
            <PackageTableReport />
          </AuthorizedRoute>
        ),
      },

      // {
      //   path: "/sales-by-manager-table-report",
      //   exact: true,
      //   element: (
      //     <AuthorizedRoute allowedRoles={["CXO"]}>
      //       < />
      //     </AuthorizedRoute>
      //   ),
      // },
      {
        path: "/activation-by-teamlead",
        exact: true,
        element: (
          <AuthorizedRoute
            allowedRoles={["CXO", "SALES_TEAM_LEAD", "SALES_MANAGER"]}
          >
            <ActivationTeamlead />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/activation-by-packageName",
        exact: true,
        element: (
          <AuthorizedRoute
            allowedRoles={["CXO", "SALES_TEAM_LEAD", "SALES_MANAGER"]}
          >
            <ActivationByPackage />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/activation-by-paymentMode",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["CXO", "SALES_MANAGER"]}>
            <ActivationByPMode />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/promocode-details/:promocode",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["CXO", "SALES_MANAGER"]}>
            <PromoCodeDetails />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/promocode-details/",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["CXO", "SALES_MANAGER"]}>
            <PromoCodeDetails />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/promo-details",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["CXO", "SALES_MANAGER"]}>
            <PromoDetails />
          </AuthorizedRoute>
        ),
      },
      // {
      //   path: "/rsa-plan-report",
      //   exact: true,
      //   element: (
      //     <AuthorizedRoute allowedRoles={["CXO", "SALES_MANAGER"]}>
      //       <RsaReport />
      //     </AuthorizedRoute>
      //   ),
      // },

      {
        path: "/complaints-report",
        exact: true,
        element: (
          <AuthorizedRoute
            allowedRoles={[
              "CXO",
              "CONTROL_ROOM_LEAD",
              "CONTROL_ROOM_MANAGER",
              "FINANCE",
              "CALL_CENTER_LEAD",
            ]}
          >
            <ComplaintsReport />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/services-report",
        exact: true,
        element: (
          <AuthorizedRoute
            allowedRoles={[
              "CXO",
              "CONTROL_ROOM_LEAD",
              "CONTROL_ROOM_MANAGER",
              "FINANCE",
              "CALL_CENTER_LEAD",
            ]}
          >
            <ServicesReport />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/complaint-log-table-report",
        exact: true,
        element: (
          <AuthorizedRoute
            allowedRoles={["CXO", "CONTROL_ROOM_LEAD","CONTROL_ROOM_MANAGER", "FINANCE"]}
          >
            <ComplaintLogTableReport />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/invoices-report",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["CXO"]}>
            <InvoiceReport />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/important-section-it",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["IT"]}>
            <ImportanctSectionIT />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/payment-clearence-report",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["CXO"]}>
            <PaymentClearenceReport />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/cash-and-online-clearence-report",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["CXO", "FINANCE"]}>
            <CashClearenceReport />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/fe-activations-report",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["CXO", "FIELD_EXECUTIVE_MANAGER"]}>
            <FEActivationsReport />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/Change-password",
        exact: true,
        element: (
          <AuthorizedRoute
            allowedRoles={[
              "SALES_TEAM_LEAD",
              "AGENT",
              "CALL_CENTER_LEAD",
              "CONTROL_ROOM_LEAD",
              "CONTROL_ROOM_MANAGER",
              "CXO",
              "ADMIN",
              "MECHANIC",
              "FIELD_EXECUTIVE_MANAGER",
              "IT",
              "SALES_MANAGER",
              "FINANCE",
              "DSA",
            ]}
          >
            <ChangePassword />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/sales",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["CXO"]}>
            <Sales />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/sales-chart-by-managers",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["CXO"]}>
            <ManagersSales />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/sales-by-TL",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["CXO"]}>
            <TLSales />
          </AuthorizedRoute>
        ),
      }, //
      {
        path: "/sales-chart-by-packagename",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["CXO"]}>
            <PackageSales />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/sales-chart-by-paymentmode",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["CXO"]}>
            <SalesMode />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/chart-quick-rsa-data",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["CXO"]}>
            <QuickRsa />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/invoice-list",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["FINANCE"]}>
            <InvoiceData />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/pending-payment-collection",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["FINANCE"]}>
            <PendingPayemntCollection />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/generate-physical-cards",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["FINANCE"]}>
            <PhysicalCardGeneration />
          </AuthorizedRoute>
        ),
      },
      // {
      //   path: "/activate-dsa-cards",
      //   exact: true,
      //   element: (
      //     <AuthorizedRoute
      //       allowedRoles={["FINANCE"]}
      //     >
      //       <DsaActivation />
      //     </AuthorizedRoute>
      //   ),
      // },
      {
        path: "/dsa-member-list",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["FINANCE", "SALES_MANAGER"]}>
            <DsaMemberList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/dsa-sales-report",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["FINANCE", "CXO", "DSA", "SALES_MANAGER"]}>
            <DsaReport />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/assign-dsa-cards",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["FINANCE"]}>
            <AssignDsaCards />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/cheque-payments",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["FINANCE"]}>
            <InvoicePayments />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/referral-reports",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["FINANCE", "CXO"]}>
            <ReferralReports />
          </AuthorizedRoute>
        ),
      },
      // {
      //   path: "/latest-referral",
      //   exact: true,
      //   element: (
      //     <AuthorizedRoute
      //       allowedRoles={["FINANCE", "CXO"]}
      //     >
      //       <LatestReferrals />
      //     </AuthorizedRoute>
      //   ),
      // },
      {
        path: "/manage-user-referral",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["FINANCE", "CXO"]}>
            <ManageUserReferral />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/invoice-composer",
        children: [
          {
            path: "/invoice-composer/",
            exact: true,
            element: (
              <AuthorizedRoute allowedRoles={["FINANCE"]}>
                <InvoiceComposer />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/invoice-composer/:id",
            exact: true,
            element: (
              <AuthorizedRoute allowedRoles={["FINANCE"]}>
                <InvoiceComposer />
              </AuthorizedRoute>
            ),
          },
        ],
      },
      {
        path: "/mechanics-list",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["CONTROL_ROOM_LEAD", "CONTROL_ROOM_MANAGER", "IT"]}>
            <EditMechanicDetails />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/create-new-mechanic",
        children: [
          {
            path: "/create-new-mechanic/",
            exact: true,
            element: (
              <AuthorizedRoute allowedRoles={["CONTROL_ROOM_LEAD", "CONTROL_ROOM_MANAGER", "IT"]}>
                <AddNewMechanic />
              </AuthorizedRoute>
            ),
          },
          {
            path: "/create-new-mechanic/:id",
            exact: true,
            element: (
              <AuthorizedRoute allowedRoles={["CONTROL_ROOM_LEAD", "CONTROL_ROOM_MANAGER", "IT"]}>
                <AddNewMechanic />
              </AuthorizedRoute>
            ),
          },
        ],
      },
      {
        path: "/edit-complaint/:id",
        exact: true,
        element: (
          <AuthorizedRoute
            allowedRoles={["CONTROL_ROOM_LEAD", "CALL_CENTER_LEAD", "CONTROL_ROOM_MANAGER"]}
          >
            <EditComplaint />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/assigned-and-activate-dsa-cards",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["FINANCE", "DSA"]}>
            <AssignedCards />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/add-dsa-channel",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["FINANCE", "SALES_MANAGER"]}>
            <AddDSAChannel />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/edit-dsa-channel/:id",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["FINANCE"]}>
            <EditDSAChannel />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/activate-dsa-cards",
        allowedRoles: "DSA",
        children: [
          {
            path: "/activate-dsa-cards/:user_id",
            exact: true,
            element: (
              <AuthorizedRoute allowedRoles={["FINANCE", "DSA"]}>
                <DsaActivation />
              </AuthorizedRoute>
            ),
          },
        ],
      },
      // {
      //   path: "/edit-cx-details",
      //   children: [
      //     {
      //       path: "/edit-cx-details/:prospect_id",
      //       exact: true,
      //       element: (
      //         <AuthorizedRoute allowedRoles={["SALES_TEAM_LEAD"]}>
      //           <EditGenerateProductLink />
      //         </AuthorizedRoute>
      //       ),
      //     },
      //   ],
      // },
      {
        path: "/leads-assign-rsa-help",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["SALES_TEAM_LEAD", "SALES_MANAGER"]}>
            <LeadsAssignRSAhelp />
          </AuthorizedRoute>
        ),
      },
      { path: "access", element: <AccessDenied /> },
      { path: "error", element: <OOPSError /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },

  {
    path: "/auth",
    element: (
      <PublicRoute>
        <BlankLayout />
      </PublicRoute>
    ),
    children: [
      { path: "404", element: <Error /> },
      { path: "login", element: <Login /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
];
const PromoCodeDetailsWrapper = () => {
  const { promocode, count } = useParams<{
    promocode: string;
    count: string;
  }>();

  return <PromoCodeDetails promocode={promocode} count={count} />;
};
