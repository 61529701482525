import { LoadState } from "../../constants/enums";
import { IGoogleNearBy, IGoogleNearByState, IMechanic, IMechanicState, IUpsertMechanic } from "./mechanics.types";


export const defaultMechanic: IMechanic = {
    mechanic_id_v: null,
    mechanic_id: "",
    "username_v": "",
    "email_id_v": "",
    "password_v": "",
    "branch_office_v": "",
    "contact_no_v": "",
    "mechanic_type_v": "",
    "state_v": "",
    "city_v": "",
    "location_v": "",
    "address_v": "",
    "pin_v": "",
    "latitude_v": 0,
    "longitude_v": 0,
    "payout_type_v": "",
    "nodal_v": "",
    "agreed_amount_v": 0,
    "mechanic_shift_v": "",

    address_line1: null,
    address_line2: null,
    city: null,
    state: null,
    country: null,
    pin: null,

}
    

export const defaultMechanicState: IMechanicState = {
    mechanics_list: {
        loading: LoadState.NotLoaded,
        data: [],
        totalRecords: 0,
        error: null
    },
    single_mechanic: {
        loading: LoadState.NotLoaded,
        data: defaultMechanic,
        error: null
    }
}

export const defaultGoogleNearBy: IGoogleNearBy={
    lat: 0,
    lng: 0,
}

export const defaultGoogleNearByState: IGoogleNearByState={
    location:{
        data:defaultGoogleNearBy,
        loading: LoadState.NotLoaded,
        error: null
    }
}


export const defaultUpsertMechanic:IUpsertMechanic={
    backoffice_users_id:0,
    username_v:"",
    email_id_v: "",
    password_v: "",
    branch_office_v: "",
    contact_no_v: "",
    mechanic_type_v: "",
    state_v: "",
    city_v: "",
    location_v: "",
    address_v: "",
    pin_v: "",
    latitude_v: 0,
    longitude_v: 0,
    payout_type_v: "",
    nodal_v: "",
    agreed_amount_v: 0,
    mechanic_shift_v: "",
    status_v: "Active",
  }