import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { getUserAuth } from "../../helpers";
import { IStoreState } from "../initialStoreState";
import { FETCH_GOOGLE_NEAR_BY_ERROR, FETCH_GOOGLE_NEAR_BY_PROGRESS, FETCH_GOOGLE_NEAR_BY_SUCCESS, FETCH_MECHANICS_LIST_ERROR, FETCH_MECHANICS_LIST_PROGRESS, FETCH_MECHANICS_LIST_SUCCESS, FETCH_SINGLE_MECHANIC_ERROR, FETCH_SINGLE_MECHANIC_PROGRESS, FETCH_SINGLE_MECHANIC_SUCCESS } from "./mechanics.constants";
import { IGoogleNearBy, IMechanic, IUpsertMechanic } from "./mechanics.types";
import { saveLoaderCompleted, saveLoaderProgress, showMessage } from "../messages/messagesActions";
import { get } from "lodash";
import { ICommonMechanic } from "../common/types";


export const fetchMechanicsListProgress = () =>
    action(FETCH_MECHANICS_LIST_PROGRESS);
export const fetchMechanicsListSuccess = (data: IMechanic[]) =>
    action(FETCH_MECHANICS_LIST_SUCCESS, { data });
export const fetchMechanicsListError = () => action(FETCH_MECHANICS_LIST_ERROR);

export const fetchMechanicsListAsync =
    (): ThunkAction<void, IStoreState, {}, AnyAction> =>
        async (dispatch, getState) => {
            try {
                dispatch(fetchMechanicsListProgress());
                const token = getUserAuth();
                if (token) {
                    const res = await api.get("/get-mechanic-list", {
                        headers: {
                            "auth-Token": token,
                        }
                    });
                    dispatch(fetchMechanicsListSuccess(res.data.result));
                }
            } catch (err) {
                dispatch(fetchMechanicsListError());
            }
        };




export const fetchSingleMechanicProgress = () =>
    action(FETCH_SINGLE_MECHANIC_PROGRESS);
export const fetchSingleMechanicSuccess = (data: IMechanic) =>
    action(FETCH_SINGLE_MECHANIC_SUCCESS, { data });
export const fetchSingleMechanicError = () => action(FETCH_SINGLE_MECHANIC_ERROR);

export const fetchSingleMechanicAsync =
    (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
        async (dispatch, getState) => {
            try {
                dispatch(fetchSingleMechanicProgress());
                const token = getUserAuth();
                if (token) {
                    const res = await api.get(`/get-mechanic-list?mechanic_id_v=${uuid}`, {
                        headers: {
                            "auth-Token": token,
                        }
                    });
                    const mechanicData = res.data[0]
                    const data: IMechanic = {
                        mechanic_id_v: mechanicData.backoffice_users_id,
                        mechanic_id: mechanicData.mechanic_id,
                        username_v: mechanicData.user_name,
                        email_id_v: mechanicData.email_id,
                        contact_no_v: mechanicData.contact_no,
                        password_v: mechanicData.password,
                        branch_office_v: mechanicData.branch_office,
                        state_v: mechanicData.state as string,
                        city_v: mechanicData.city as string,
                        location_v: mechanicData.location,
                        address_v: mechanicData.address,
                        pin_v: mechanicData.pin as string,
                        mechanic_shift_v: mechanicData.mechanic_shift,
                        mechanic_type_v: mechanicData.mechanic_type,
                        latitude_v: 0,
                        longitude_v: 0,
                        payout_type_v: mechanicData.payout_type,
                        nodal_v: mechanicData.nodal,
                        agreed_amount_v: mechanicData.agreed_amount,

                        address_line1: null,
                        address_line2: null,
                        city: null,
                        state: null,
                        country: null,
                        pin: null,
                    }
                    dispatch(fetchSingleMechanicSuccess(data));
                }
            } catch (err) {
                dispatch(fetchSingleMechanicError());
            }
        };





export const upsertMechanicAsync =
    (
        data: IUpsertMechanic,
        onCallback: (isSuccess: boolean, data?: IUpsertMechanic) => void,
    ): ThunkAction<void, IStoreState, {}, AnyAction> =>
        async (dispatch, getState) => {
            try {
                dispatch(saveLoaderProgress());
                const {  ...rest } = data;
               
                const res = await api.post("/upsert-mechanic", rest);

                onCallback(true, res.data.data);
                dispatch(
                    showMessage({
                        type: "success",
                        message: "Mechanic saved successfully!",
                        displayAs: "snackbar",
                    }),
                );
            } catch (err: any) {
                onCallback(false);
                dispatch(
                    showMessage({
                        type: "error",
                        message: err.response.data.message,
                        displayAs: "snackbar",
                    }),
                );
            } finally {
                dispatch(saveLoaderCompleted());
            }
        };


       

export const fetchMechanicsListByPlaceIdAsync =
    (place_id: string, radius: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
        async (dispatch, getState) => {
            try {
                dispatch(fetchMechanicsListProgress());
                const token = getUserAuth();
                if (token) {
                    const res = await api.get(`/get-mechanic-by-location?place_id=${place_id}&radius=${radius}`, {
                        headers: {
                            "auth-Token": token,
                        }
                    });
                    const newData = (res.data.data || []).map((data: any) => {
                        return {
                            ...data,
                            mechanic_id_v: data.backoffice_users_id,
                            mechanic_id: data.mechanic_id,
                            username_v: data.user_name,
                            email_id_v: data.email_id,
                            contact_no_v: data.contact_no,
                            password_v: data.password,
                            branch_office_v: data.branch_office,
                            state_v: data.state as string,
                            city_v: data.city as string,
                            location_v: data.location,
                            address_v: data.address,
                            pin_v: data.pin as string,
                            mechanic_shift_v: data.mechanic_shift,
                            mechanic_type_v: data.mechanic_type,
                            latitude_v: 0,
                            longitude_v: 0,
                            payout_type_v: data.payout_type,
                            nodal_v: data.nodal,
                            agreed_amount_v: data.agreed_amount,

                            address_line1: null,
                            address_line2: null,
                            city: null,
                            state: null,
                            country: null,
                            pin: null,
                        }
                    })
                    dispatch(fetchMechanicsListSuccess(newData));
                }
            } catch (err) {
                dispatch(fetchMechanicsListError());
            }
        };

//*************************fetch google near by location ********************** */
        export const fetchGoogleNearByProgress = () =>
            action(FETCH_GOOGLE_NEAR_BY_PROGRESS);
        export const fetchGoogleNearBySuccess = (data: IGoogleNearBy) =>
            action(FETCH_GOOGLE_NEAR_BY_SUCCESS, { data });
        export const fetchGoogleNearByError = (errorMessage: string) => action(FETCH_GOOGLE_NEAR_BY_ERROR, { errorMessage });


        export const fetchGoogleNearBy =(
            place_id: string,): ThunkAction<void, IStoreState, {}, AnyAction> =>async(dispatch, getState) => {
                try {
                    dispatch(fetchGoogleNearByProgress());
                    const token = getUserAuth();

                    let url = `/get-google-nearby?place_id=${place_id}`;
                    if (token) {
                        const res = await api.get(url, {
                            headers: {
                                "auth-Token": token,
                            }
                        });
                        dispatch(fetchGoogleNearBySuccess(res.data.data));
                    }
                } catch (err) {
                    dispatch(fetchGoogleNearByError("Something went to be wrong!"));
                }
            }
