import { LoadState } from "../../constants/enums";

export interface IPromoState {
  PromoCode: IPromoCode[];

  loadState: LoadState;
}

export const initialPromoState: IPromoState = {
  PromoCode: [],
  loadState: LoadState.NotLoaded,
};

export interface IPromoCode {
  promo_code_id: number;
  promo_code: string;
  deduction: number;
  type: string;
  package_id: number;
  partner_id: number;
  from_date: string;
  to_date: string;
  status: string;
  comment: string;
  insert_dt: string;
}
