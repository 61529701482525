
export const FETCH_MECHANICS_LIST_PROGRESS = "FETCH_MECHANICS_LIST_PROGRESS"
export const FETCH_MECHANICS_LIST_SUCCESS = "FETCH_MECHANICS_LIST_SUCCESS"
export const FETCH_MECHANICS_LIST_ERROR = "FETCH_MECHANICS_LIST_ERROR"

export const FETCH_SINGLE_MECHANIC_PROGRESS = "FETCH_SINGLE_MECHANIC_PROGRESS"
export const FETCH_SINGLE_MECHANIC_SUCCESS = "FETCH_SINGLE_MECHANIC_SUCCESS"
export const FETCH_SINGLE_MECHANIC_ERROR = "FETCH_SINGLE_MECHANIC_ERROR"


export const FETCH_GOOGLE_NEAR_BY_PROGRESS = "FETCH_GOOGLE_NEAR_BY_PROGRESS"
export const FETCH_GOOGLE_NEAR_BY_SUCCESS = "FETCH_GOOGLE_NEAR_BY_SUCCESS"
export const FETCH_GOOGLE_NEAR_BY_ERROR = "FETCH_GOOGLE_NEAR_BY_ERROR"