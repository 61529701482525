import { LoadState } from "../../constants/enums";

interface IRole {
  role_id: number;
  role_name: string;
  hierarchy: number;
  status: string;
  insert_dt: string;
}

export type vehicleType = "FOUR_WHEELER" | "TWO_WHEELER";

export interface ISelect {
  label: string;
  value: string | number;
}

export interface ICommonMechanic {
  backoffice_users_id: number;
  mechanic_shift?: string;
  user_id?: number;
  user_name: string;
  email_id?: string;
  role_id?: number;
  hierarchy?: number;
  entry_dt?: string;
  last_login?: string;
  address?: string;
  referral_code?: string | null;
  u_id?: string;
  expire_on?: string;
  status?: string;
  insert_dt?: string;
  nodal?: string;
  city?: string;
  mechanic_type?: string;
  agreed_amount?: number;
  contact_no?: string;

  latitude?: string;
  longitude?: string;
  location?: string;
  branch_office?: string;
  payout_type?: string;
  pin?: string;
  state?: string;
  total_services_done?: number;
  total_earned_amount?: string | null;
}

export interface IState {
  state_id: number;
  state_code: number;
  state_name: string;
  status: string;
  insert_dt: string;
}

export interface ICity {
  city_id: number;
  city_code: number;
  city_name: string;
  state_id: number;
  status: string;
  insert_dt: string;
}

export interface IArea {
  area_id: number;
  area_name: string;
  city_id: number;
  status: string;
  insert_dt: string;
}

export interface ISowPackage {
  sow_package_id: number;

  package_name: string;
  package_type: string;
  fuel_type: string;
  package_price_excluding_gst: number;

  package_price: number;
  gst_percentage: number;
  gst_amount: number;
  vehicle_type: string;
  city: string;
  duration: number;
  partner_name: string;
  no_of_services: number;
  insert_dt: string;
  status: string;
  comment: string;
}

export interface IPackage {
  package_id: number;
  old_package_id: number;
  package_name: string;
  package_price_excluding_gst: number;
  package_price: number;
  gst_percentage: number;
  gst_amount: number;
  vehicle_type: string;
  city: string;
  duration: number;
  partner_name: string;
  no_of_services: number;
  insert_dt: string;
  status: string;
  comment: string;
}

export interface ILead {
  user_name: string;
  user_id: number;
  email_id: string;
  entry_dt: string;
  referral_code: string;
}

export interface ISFA {
  backoffice_users_id: number;
  user_name: string;
  email_id: string;
  role_id: number;
  referral_code: number | null;
}

export interface IManager {
  backoffice_users_id: number;
  user_name: string;
  role_name: string;
}
export interface IReferrals {
  backoffice_users_id: number;
  user_name: string;
  referral_code: string;
}

export interface IBranchOffice {
  result: string[];
}

export interface IReporteeReferrals {
  branch_office: string;
  reportee_id: number;
  reportee: string;
  reportee_role: string;
  reportee_email_id: string;
  reportee_referral_code: string;
  manager_id: number;
  reporting_manager: string;
  manager_role: string;
  email_id: string;
}
/************************************* */
export interface ISalesManager {
  backoffice_users_id: number;
  user_name: string;
  referral_code: string;
}

export interface ICommon {
  roles: {
    list: IRole[];
    loadState: LoadState;
  };
  faultType: {
    list: {
      [key: string]: ISelect[];
    };
    loadState: LoadState;
  };
  faultName: {
    list: {
      [key: string]: ISelect[];
    };
    loadState: LoadState;
  };
  subFaultName: {
    list: {
      [key: string]: ISelect[];
    };
    loadState: LoadState;
  };
  mechanics: {
    list: ICommonMechanic[];
    loadState: LoadState;
  };
  state: {
    list: IState[];
    loadState: LoadState;
  };
  city: {
    list: {
      [stateId: string]: ICity[];
    };
    loadState: LoadState;
  };
  packages: {
    list: IPackage[];
    loadState: LoadState;
  };
  sowPackages: {
    list: ISowPackage[];
    loadState: LoadState;
  };
  brands: {
    list: string[];
    loadState: LoadState;
  };
  models: {
    list: {
      [brandname: string]: string[];
    };
    loadState: LoadState;
  };
  salesLeads: {
    list: ISelect[];
    loadState: LoadState;
  };
  sfa: {
    list: ISelect[];
    loadState: LoadState;
  };
  managers: {
    list: ISelect[];
    loadState: LoadState;
  };
  referrals: {
    list: ISelect[];
    loadState: LoadState;
  };
  reportee_referrals: {
    list: IReporteeReferrals[];
    loadState: LoadState;
  };
  branchOffice: {
    Officelist: IBranchOffice[];
    loadState: LoadState;
  };
}

export const initialCommonState: ICommon = {
  roles: {
    list: [],
    loadState: LoadState.NotLoaded,
  },
  faultType: {
    list: {},
    loadState: LoadState.NotLoaded,
  },
  faultName: {
    list: {},
    loadState: LoadState.NotLoaded,
  },
  subFaultName: {
    list: {},
    loadState: LoadState.NotLoaded,
  },
  mechanics: {
    list: [],
    loadState: LoadState.NotLoaded,
  },
  state: {
    list: [],
    loadState: LoadState.NotLoaded,
  },
  city: {
    list: {},
    loadState: LoadState.NotLoaded,
  },
  packages: {
    list: [],
    loadState: LoadState.NotLoaded,
  },
  sowPackages: {
    list: [],
    loadState: LoadState.NotLoaded,
  },
  brands: {
    list: [],
    loadState: LoadState.NotLoaded,
  },
  models: {
    list: {},
    loadState: LoadState.NotLoaded,
  },
  salesLeads: {
    list: [],
    loadState: LoadState.NotLoaded,
  },
  sfa: {
    list: [],
    loadState: LoadState.NotLoaded,
  },
  managers: {
    list: [],
    loadState: LoadState.NotLoaded,
  },
  referrals: {
    list: [],
    loadState: LoadState.NotLoaded,
  },
  reportee_referrals: {
    list: [],
    loadState: LoadState.NotLoaded,
  },
  branchOffice: {
    Officelist: [],
    loadState: LoadState.NotLoaded,
  },
};
